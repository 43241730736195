import JoditEditor from "jodit-react";
import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import { API_URL, STORAGE_URL, URL } from "../../variables";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import ModalFormula from './ModalFormula';
import FormulaFx from '../../assets/images/formula-4.png';

class ModalCadastroQuestao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',
            alternativa_a: '',
            alternativa_b: '',
            alternativa_c: '',
            alternativa_d: '',
            alternativa_e: '',
            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
            corretas: [
                { id: 'a', 'name': 'a)' },
                { id: 'b', 'name': 'b)' },
                { id: 'c', 'name': 'c)' },
                { id: 'd', 'name': 'd)' },
                { id: 'e', 'name': 'e)' },

            ],

            categoria: { name: '' },
            type_questao: 'simples',
            questoes: [],

            numero_alternativas: [5, 4, 3, 2, 1],
            numero_alternativa: 5,
            solucao:'',

            loading_image_solucao: false,
            showModalFormula:false

        };

        this.load_configs();


    }

    componentWillUnmount(){
        Array.from(document.getElementsByClassName('jodit-wysiwyg')).forEach((el) => {
            // Do stuff here
            el.blur()
        })
    }


    load_configs() {
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }



        this.config_a = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_a,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_a: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_a: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_a: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


        this.config_b = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_b,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_b: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_b: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_b: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }





        this.config_c = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_c,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_c: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_c: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_c: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }





        this.config_d = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_d,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_d: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_d: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_d: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


        this.config_e = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_e,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_e: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_e: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_e: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
        this.config_solucao = {
            height: 300,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_only_text',
            defaultActionOnPasteFromWord: 'insert_only_text',
            pasteHTMLActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList: [
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_solucao,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons: ['video', 'fullsize', 'source', 'copyformat', 'about', 'link', 'classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_solucao: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_solucao: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if (filename.includes('.mp4')) {
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if (filename.includes('.mp3')) {
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else {
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_solucao: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }



    componentDidMount(){
        if(this.props.questaoSelect!=null){
            let item = this.props.questaoSelect
            this.setState({
                enunciado:item.name,
                numero_alternativa:item.alternativas.length,
                alternativa_a:item.alternativas[0]?item.alternativas[0].name:'',
                alternativa_b:item.alternativas[1]?item.alternativas[1].name:'',
                alternativa_c:item.alternativas[2]?item.alternativas[2].name:'',
                alternativa_d:item.alternativas[3]?item.alternativas[3].name:'',
                alternativa_e:item.alternativas[4]?item.alternativas[4].name:'',
                solucao:item.solucao ?? '',
                correta:item.correta
            })
        }
    }

    render() {
        return (
            <>
            {this.props.showModal && <ModalFormula setImage={(image,html)=>{
                                        let  img =document.createElement('img')
                                        img.src=image
                                        console.log(this.editor_enunciado)
                                        this.editor_select.selection.insertNode(img)
                                        // this.editor_enunciado.selection.insertHTML(html)
                                                    // this.setState({ enunciado:'<p>'+html+'</p>' })

                                }} showModal={this.state.showModalFormula} setShowModal={(e) => this.setState({ showModalFormula: e })} />}

            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>{this.props.index_select==-1? 'Cadastrar':'Editar'} Questão - {this.props.categoria.name}<br /></Modal.Title></Modal.Header>
                <Modal.Body>
                    
                    <div >
                        {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Enunciado <Attention /> {this.state.loading_image_enunciado == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_enunciado}
                                    value={this.state.enunciado}
                                    config={this.config_enunciado}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>

                        <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Número de alternativas <Attention /></Form.Label>
                                <select className="form-select " onChange={(e) => {

                                    this.setState({ numero_alternativa: e.target.value, correta: '' })
                                }} value={this.state.numero_alternativa}>

                                    {this.state.numero_alternativas.map((item, id) => (
                                        <option
                                            key={id}
                                            value={item}
                                        >{item}</option>
                                    ))
                                    }

                                </select>
                            </div>
                        </InputGroup>

                        <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Alternativa a) <Attention /> {this.state.loading_image_a == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_a}
                                    value={this.state.alternativa_a}
                                    config={this.config_a}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ alternativa_a: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>

                        {this.state.numero_alternativa>=2 && <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Alternativa b) <Attention /> {this.state.loading_image_b == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_b}
                                    value={this.state.alternativa_b}
                                    config={this.config_b}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ alternativa_b: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>}

                        {this.state.numero_alternativa>=3 && <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Alternativa c) <Attention /> {this.state.loading_image_c == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_c}
                                    value={this.state.alternativa_c}
                                    config={this.config_c}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ alternativa_c: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>}

                        {this.state.numero_alternativa>=4 && <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Alternativa d) <Attention /> {this.state.loading_image_d == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_d}
                                    value={this.state.alternativa_d}
                                    config={this.config_d}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ alternativa_d: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>}

                        {this.state.numero_alternativa>=5 && <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >Alternativa e) <Attention /> {this.state.loading_image_e == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                </div>}</Form.Label>
                                <JoditEditor
                                    ref={this.editor_e}
                                    value={this.state.alternativa_e}
                                    config={this.config_e}
                                    height={400}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => this.setState({ alternativa_e: newContent })} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                />
                            </div>
                        </InputGroup>}

                        <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Qual a alternativa correta? <Attention /></Form.Label>
                                <select className="form-select " onChange={(e) => this.setState({ correta: e.target.value })} value={this.state.correta}>
                                    <option value="">Selecione uma alternativa correta</option>
                                    {(this.state.corretas && this.state.corretas.length != 0) && this.state.corretas.map((value, index) => (
                                        index<=this.state.numero_alternativa-1 &&  <option
                                            key={index}
                                            value={value["id"]}
                                        >{value["name"]}</option>
                                    ))}
                                </select>
                            </div>
                        </InputGroup>

                        {<InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Solução</Form.Label>
                                            <JoditEditor
                                                ref={this.editor_solucao}
                                                value={this.state.solucao}
                                                config={this.config_solucao}
                                                height={300}
                                                tabIndex={1}
                                                onBlur={newContent => this.setState({ solucao: newContent })}
                                            />
                                        </div>
                                    </InputGroup>}

                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}

                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                        <DefaultButton
                            type="button"
                            onClick={() => {
                                console.log(this.state.numero_alternativa)
                                document.getElementById('text_enunciado').innerHTML = this.state.enunciado;
                                let data={};
                                data.alternativas=[]

                                if (document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.enunciado.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Enunciado é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                

                                document.getElementById('text_enunciado').innerHTML = this.state.alternativa_a;

                                if (this.state.numero_alternativa>=1 && document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.alternativa_a.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Alternativa a) é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                else if (this.state.numero_alternativa>=1){
                                    data.alternativa_a=this.state.alternativa_a;
                                    data.alternativas.push({
                                        name: this.state.alternativa_a,
                                        correta: this.state.correta == 'a',
                                        order: 1
                                    })
                                }

                                document.getElementById('text_enunciado').innerHTML = this.state.alternativa_b;

                                if (this.state.numero_alternativa>=2 && document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.alternativa_b.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Alternativa b) é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                else if (this.state.numero_alternativa>=2){
                                    data.alternativa_b=this.state.alternativa_b;
                                    data.alternativas.push({
                                        name: this.state.alternativa_b,
                                        correta: this.state.correta == 'b',
                                        order: 2
                                    })
                                }

                                document.getElementById('text_enunciado').innerHTML = this.state.alternativa_c;
                                if (this.state.numero_alternativa>=3 && document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.alternativa_c.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Alternativa c) é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                else if (this.state.numero_alternativa>=3){
                                    data.alternativa_c=this.state.alternativa_c;
                                    data.alternativas.push({
                                        name: this.state.alternativa_c,
                                        correta: this.state.correta == 'c',
                                        order: 3
                                    })
                                }


                                document.getElementById('text_enunciado').innerHTML = this.state.alternativa_d;
                                if (this.state.numero_alternativa>=4 && document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.alternativa_d.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Alternativa d) é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                else if (this.state.numero_alternativa>=4){
                                    data.alternativa_d=this.state.alternativa_d;
                                    data.alternativas.push({
                                        name: this.state.alternativa_d,
                                        correta: this.state.correta == 'd',
                                        order: 4
                                    })
                                }

                                document.getElementById('text_enunciado').innerHTML = this.state.alternativa_e;
                                if (this.state.numero_alternativa>=5 && document.getElementById('text_enunciado').textContent.trim().length == 0 && !this.state.alternativa_e.includes('<img')) {
                                    this.setState({ msgErro: 'O campo Alternativa e) é obrigatório' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                else if (this.state.numero_alternativa>=5){
                                    data.alternativa_e=this.state.alternativa_e;
                                    data.alternativas.push({
                                        name: this.state.alternativa_e,
                                        correta: this.state.correta == 'e',
                                        order: 5
                                    })
                                }

                                if (this.state.correta == '') {
                                    this.setState({ msgErro: 'Selecione uma alternativa correta' });
                                    document.getElementsByClassName('modal-dialog')[0].parentElement.scrollTo(0, 0);
                                    return;
                                }
                                console.log(data);

                                document.getElementById('text_enunciado').innerHTML = this.state.enunciado;

                                this.props.salvarQuestao({
                                    name: this.state.enunciado,
                                    correta: this.state.correta,
                                    ...data,
                                    numero_alternativa:this.state.numero_alternativa,
                                    solucao:this.state.solucao,

                                    text_questao: document.getElementById('text_enunciado').textContent,
                                    categoria: this.props.categoria
                                });
                                this.setState({ enunciado: '', alternativa_a: '', alternativa_b: '', alternativa_c: '', alternativa_d: '', alternativa_e: '', correta: '', msgErro: '' });


                            }}
                            bg="confirm"
                            text="Salvar questão"
                            disabled={this.state.loading_image_enunciado == true || this.state.loading_image_a || this.state.loading_image_b || this.state.loading_image_c || this.state.loading_image_d || this.state.loading_image_e}
                            loadingtext="Salvando questão"
                            loading={this.state.loading_save}
                        />
                    </div>
                </Modal.Footer>

            </Modal>
            </>

        );
    }
}

export default ModalCadastroQuestao;