import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import moment from "moment";
import "./mathlive.css";
import "mathlive";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { convertLatexToMarkup, convertLatexToSpeakableText } from 'mathlive'
import DefaultButton from "../ReusableComponents/DefaultButton";
import { CircularProgress } from "@material-ui/core";

const ModalFormula = props => {
    const { showModal, setShowModal,setImage } = props;
    const [value,setValue] = useState('');
    const [latex,setLatex] = useState('');
    const [msgErro,setMsgErro] = useState('');

    const [loadingFormula,setLoadingFormula] = useState(false);

    const showFormula = useCallback(() => {
        return (<>
        <p>Abra o teclado de fórmula clicando em <span style={{fill:'hsl(212, 40%, 50%)'}} dangerouslySetInnerHTML={{__html:'<svg xmlns="http://www.w3.org/2000/svg" style="width: 21px;" viewBox="0 0 576 512" role="img" aria-label="Alternar teclado virtual"><path d="M528 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm16 336c0 8.823-7.177 16-16 16H48c-8.823 0-16-7.177-16-16V112c0-8.823 7.177-16 16-16h480c8.823 0 16 7.177 16 16v288zM168 268v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm-336 80v-24c0-6.627-5.373-12-12-12H84c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm384 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zM120 188v-24c0-6.627-5.373-12-12-12H84c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm96 0v-24c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12zm-96 152v-8c0-6.627-5.373-12-12-12H180c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h216c6.627 0 12-5.373 12-12z"></path></svg>'}}></span></p>
        <p>Aperte SHIFT em cada aba do teclado para ver mais opções</p>
            <math-field
                style={{width:'100%'}}
                id="math_id"
                onInput={
                    (evt) => {
                        console.log(evt.target.value);
                        setValue(evt.target.value);
                        setLatex(convertLatexToMarkup(evt.target.value))
                    }
                }
            >
                {value ?? ''}
            </math-field>
        </>)
    }, [value,latex]);
    return (
        <>
                {<div id="formula" style={{width:'fit-content',zIndex:-1,position:'relative',padding:0}}  dangerouslySetInnerHTML={{__html:latex}}></div>}

            {showModal == true  && 
            <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            // onHide={() => this.props.setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Adicionar Fórmula<br /></Modal.Title></Modal.Header>
            <Modal.Body>
            <div style={{width:'100%',}}>
                {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>


                                <p style={{ marginBottom: 0}}>{msgErro}</p>

                            </div>
                            <br />

                        </div>}
                {showFormula()}
                </div>
                </Modal.Body>

                <Modal.Footer>

                    {loadingFormula==false ? <div className="w100 inline-flex justify-content-between margin-top-1">
                    <DefaultButton
                            type="button"
                            onClick={() => {
                                setShowModal(false)
                            }}
                            bg="secondary"
                            text="Voltar"
                            disabled={loadingFormula}
                        />
                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}

                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                        <DefaultButton
                            type="button"
                            onClick={() => {
                                setLoadingFormula(true);
                                console.log(document.getElementById('formula').innerHTML)
                                toPng(document.getElementById('formula'))
                                .then(function (dataUrl) {
                                    setShowModal(false);
                                    setImage(dataUrl,latex)
                                    console.log(dataUrl)
                                    setLoadingFormula(false);

                                })
                                .catch(function (error) {
                                    setMsgErro('Algo deu errado. Tente gerar a fórmula novamente');
                                // setLoadingFormula(false);

                                });

                            }}
                            bg="confirm"
                            text="Salvar fórmula"
                            disabled={loadingFormula}
                            loadingtext="Salvando fórmula"
                            loading={loadingFormula}
                        />
                    </div>: <div className="w100 inline-flex justify-content-between margin-top-1"><CircularProgress/></div>}
                </Modal.Footer>
</Modal>}
        </>


    );
}

export default ModalFormula;