import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from "react-bootstrap";
import { logout } from '../../actions/AppActions';
import Search from '../ReusableComponents/Search';
import { Pagination } from '@material-ui/lab';
import { FaListAlt } from 'react-icons/fa';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { CircularProgress } from '@material-ui/core';
import { BsFillEyeFill } from "react-icons/bs";
import { URL } from '../../variables';

class Conteudos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            showDeleteConfirmation: false,
            contentToDelete: null,
            errorMsg: '',
            loading: false,
            contents: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            search: '',
            loading_search: true,
            isMounted: false,
        };
    }

    componentDidMount() {
        this.setState({ isMounted: true });
        this.getContents(this.state.pagination.page);
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    getContents = async (page, search = '', busca = true) => {
        if (busca && !this.state.loading_search) {
            this.setState({ loading_search: true });
        } else if (!busca && !this.state.loading_screen) {
            this.setState({ loading_screen: true });
        }

        try {
            const response = await fetch(`${URL}api/conteudo/get_contents?page=${page}&search=${search}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar conteúdos');
            }
            const data = await response.json();

            if (data.errors) {
                let errors = Object.values(data.errors);
                let errorMsg = errors.join('\n');
                console.log(errorMsg);
                this.setState({ redirect: true });
            } else {
                if (this.state.isMounted) {
                    this.setState({ contents: data.data.data, pagination: data.pagination });
                }
            }

        } catch (error) {
            console.log(error.message || 'Erro ao buscar conteúdos');
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao buscar conteúdos', redirect: true });
            }

        } finally {
            if (this.state.isMounted) {
                this.setState({ loading_search: false, loading: false });
            }
        }
    };



    handleDeleteContent = async (content) => {
        try {
            const response = await fetch(`${URL}api/conteudo/delete_content/${content.id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });
            if (!response.ok) {
                throw new Error('Erro ao excluir conteúdo');
            }
            await this.getContents();
        } catch (error) {
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao excluir conteúdo' });
            }
        } finally {
            if (this.state.isMounted) {
                this.setState({ showDeleteConfirmation: false });
                this.getContents(this.state.pagination.page, this.state.search);
            }
        }
    };

    formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        const segundos = String(data.getSeconds()).padStart(2, '0');

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    }

    render() {
        const { showDeleteConfirmation, contentToDelete, errorMsg, loading } = this.state;

        return (
            <div className="row">

                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">

                    <div className="card">

                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Conteúdos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os conteúdos do seu sistema</p>
                            <br />

                            <div className="row">

                                <div className="col-12">

                                    {this.state.loading == false && <div>


                                        <div className="flex jcsb flex-wrap mb-4">

                                            <Search placeholder="Pesquisar pelo nome do conteúdo" ValueChange={value => {
                                                clearTimeout(this.pesquisarTimeout);
                                                this.pesquisarTimeout = setTimeout(() => {
                                                    let page = { ...this.state.pagination };
                                                    page.current_page = 1;
                                                    this.setState({ pagination: page, search: value, loading_search: true });
                                                    this.getContents(1, value);
                                                }, 500);
                                            }} />

                                            <Link to="/conteudo/register/" style={{ textDecoration: "none" }}>
                                                <Button variant="success" className="flex align-center jcc">
                                                    <FaListAlt style={{ marginRight: 10 }} />
                                                    <p style={{ margin: 0 }}>Adicionar novo conteúdo</p>
                                                </Button>
                                            </Link>
                                        </div>
                                        <div className="table-rep-plugin">
                                            {loading ? (
                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>Tipo de arquivo</th>
                                                                <th>Data de criação</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.loading_search === false && this.state.contents.map((content) => (


                                                                <tr key={content.id}>
                                                                    <td>{content.name}</td>
                                                                    <td>{content.type}</td>
                                                                    <td>{this.formatarData(content.created_at)}</td>


                                                                    <td>
                                                                        <Link to={`/conteudo/show/${content.id}`} onClick={(e) => e.stopPropagation()}>
                                                                            <span className="inline-flex align-center jcc bg-warning icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} title="Gerenciar">
                                                                                <BsFillEyeFill size={17} color="white" />
                                                                            </span>
                                                                        </Link>


                                                                        <Link to={`/conteudo/edit/${content.id}`} style={{ cursor: 'pointer' }}>
                                                                            <span className="inline-flex align-center jcc bg-info icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} data-toggle="tooltip" title="Editar conteúdo">
                                                                                <MdModeEdit color="white" size={17} />
                                                                            </span>
                                                                        </Link>


                                                                        <span className="inline-flex align-center jcc bg-danger icon" style={{ width: "2em", height: "2em", borderRadius: "7px" }} onClick={() => this.setState({ contentToDelete: content, showDeleteConfirmation: true })} title="Deletar conteúdo">
                                                                            <MdDelete color="white" size={17} />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                    </table>
                                                </div>
                                            )}

                                        </div>

                                        {this.state.loading_search == true &&

                                            <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                <CircularProgress />
                                            </div></div>

                                        }
                                        {this.state.loading_search == false && this.state.contents.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum conteúdo cadastrado</p>

                                            </div>
                                        </div>}

                                        <Pagination
                                            className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                            count={this.state.pagination.last_page}
                                            value={this.state.pagination.current_page}
                                            onChange={(_, value) => {
                                                let page = { ...this.state.pagination };
                                                page.current_page = value;
                                                this.setState({ pagination: page });
                                                this.getContents(value, this.state.search);
                                            }}
                                        />

                                    </div>}

                                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <CircularProgress />
                                    </div></div>}

                                    <SweetAlert
                                        warning
                                        title={"Atenção"}
                                        show={showDeleteConfirmation}
                                        onConfirm={() => {
                                            this.handleDeleteContent(contentToDelete);
                                            this.setState({ showDeleteConfirmation: false, loading_search: true });
                                        }}
                                        onCancel={() => this.setState({ showDeleteConfirmation: false })}
                                        confirmBtnText="Sim, desejo apagar"
                                        cancelpBtnText="Cancelar"
                                    >
                                        {errorMsg ? errorMsg : "Ao deletar este conteúdo, ele não estará mais disponível no sistema"}
                                    </SweetAlert>


                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div >
        );
    }


}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(Conteudos);
