import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdCheck, MdClose, MdDelete } from "react-icons/md";
import TextReadMore from "../ReusableComponents/TextReadMore";
import moment from "moment";
import { URL } from "../../variables";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/AppActions";
import { CircularProgress } from "@material-ui/core";
import Request from "../../Request";

const ModalCorrecaoIA = props => {
    const token = useSelector(store => store.AppReducer.token);
    const { processo_name, showModal, setShowModal, processo_id, onFinish, redacao_name } = props;
    const [candidatos_csv, setCandidatosCsv] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [loading_jobs, setLoadingJobs] = useState(true);

    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });

    const [csvFile, setCsvFile] = useState(null);
    const [csvValue, setCsvValue] = useState('');

    const [msgErro, setMessageErro] = useState('');
    const dispatch = useDispatch();
    const timeout_jobs = useRef();

    const [loading, setLoading] = useState(false);


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(() => {

            setLoadingJobs(true);
            setJobs([]);
            GetJobsCsvs();
            return ()=>{
                clearTimeout(timeout_jobs.current);
            }
    }, []);

    const GetJobsCsvs = async (page = 1, search = '') => {

        let response = await Request.Get(`faculdade/get_csv_jobs/${processo_id}?page=${page}&search=${search}&type=processamento_redacoes_`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }


        if (response.pagination.current_page == page) {

            setJobs(response.data);

            setPagination(response.pagination);
            setLoadingJobs(false);


        }
        timeout_jobs.current = setTimeout(()=>{
            GetJobsCsvs()
        },15000);
    }


    


    return (
        <>
            {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageErro('')}></button>


                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                </div>
                <br />

            </div>}

            <div className="table-rep-plugin w-100">
                <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <table id="tech-companies-1" className="table table-striped">
                        <thead>
                            <tr>

                                <th>Status</th>
                                <th>Erro</th>
                                <th>Progresso</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading_jobs == false && jobs.map((item, id) => (

                                <tr key={id}>
                                    <td style={{ color: (item.status == 'Erro na execução' ? 'red' : (item.status == 'Finalizado' ? 'green' : 'darkgoldenrod')) }}>{item.status == 'Erro na execução' ? <MdClose style={{marginRight:5}} color="red"/> : (item.status == 'Finalizado' ? <MdCheck style={{marginRight:5}} color="green"/> : <CircularProgress style={{marginRight:5}} color="darkgoldenrod" size={10} />)}{item.status}</td>
                                    <td>{item.error_friendly ?? '-'}</td>
                                    <td>{item.progresso}</td>
                                    <td>{item.total_progresso}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    {loading_jobs == true &&
                        <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                            <CircularProgress />
                        </div></div>

                    }
                </div>
            </div>
            {loading_jobs == false && jobs.length == 0 && <div className="row w-100">
                <div className="col-12">
                    <p style={{ textAlign: 'center' }}>Nenhuma execução encontrada</p>

                </div>
            </div>}



            <br />
            <br />









            {loading == true && <div className="w100 inline-flex jcsb margin-top-1 d-flex justify-content-center align-items-center">
                <CircularProgress />
            </div>}

        </>

    );
}

export default ModalCorrecaoIA;