import React from 'react';
import { URL } from '../variables';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';

import Logo from '../assets/images/logo-ontest.png';
import BgVestibular from '../assets/images/bg_vestibular.jpeg';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import Attention from '../Home/ReusableComponents/Attention';
import PasswordInput from '../Home/ReusableComponents/PasswordInput';
import DefaultButton from '../Home/ReusableComponents/DefaultButton';
import moment from 'moment';


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faculdade: null,
            loading: false,
            msgErro: '',
            credencial: '',
            password: '',
            redirect: false,
            path: ''
        }

    }

    login(event) {
        this.setState({loading:true,msgErro:''});
        event.preventDefault();
        console.log(JSON.stringify({
            credencial: this.state.credencial,
            password: this.state.password,
        }))
        fetch(`${URL}api/faculdade/auth/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                credencial: this.state.credencial,
                password: this.state.password,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro, refresh: false, loadingMore: false });
                }
                else {
                    localStorage.setItem('token', resp.access_token);
                    this.props.login({ token: resp.access_token, user: {} });
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }



    componentDidMount() {
    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        final_num = text;
        this.setState({ credencial: final_num })
    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    render() {
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {/* {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                </div></div>} */}
                {<div className="row" style={{ backgroundColor: 'white',margin:0 }}>
                    <div className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4" style={{ margin: 0, padding: 0 }}>
                        <div className="card" style={{ height: '100vh', margin: 0 }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>

                                <br/>

                                {<div className="text-center">
                                    <a className="logo"><img style={{maxHeight:70}} src={Logo}
                                        height="60" alt="logo" /></a>
                                </div>}
                                <br/>

                                <div style={{ padding: '1rem', paddingBottom: 0, marginTop: 10 }}>
                                    <h4 className="font-18 m-b-5 text-center header-title" style={{ fontSize: 18 }}>Bem vindo !</h4>
                                    <p className="text-muted text-center" style={{fontSize:14}}>Faça login para continuar.</p>

                                    <form className="form-horizontal m-t-30" onSubmit={this.login.bind(this)}>

                                        {/* <div className="form-group row">
                                            <div className="col-12">
                                                <label >E-mail</label>
                                                <input id="credencial" type="username"
                                                    className="form-control  " name="credencial"
                                                    value={this.state.credencial} onChange={this.changeEmail.bind(this)} required autoComplete="email" autoFocus />

                                            </div>

                                        </div> */}

                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>E-mail <Attention /></Form.Label>
                                                <Input
                                                    type="email"
                                                    style={{fontSize:14}}
                                                    placeholder="E-mail"
                                                    value={this.state.credencial}
                                                    onChange={(e) => this.setState({ credencial: e.target.value })}
                                                    required
                                                    // title="Coloque o e-mail do usuário"
                                                    autoComplete="new-password"
                                                />
                                            </div>

                                        </InputGroup>

                                        <InputGroup>
                                            <PasswordInput
                                                label="Senha"
                                                placeholder="Digite sua senha"
                                                className="flex1"
                                                value={this.state.password}
                                                OnChangeText={(e) => this.setState({ password: e.target.value })}
                                                required
                                            // title="Coloque a senha do usuário"
                                            />
                                        </InputGroup>

                                        {/* <div className="form-group row">
                                            <div className="col-12">
                                                <label >Senha</label>
                                                <input id="password" type="password"
                                                    value={this.state.password}
                                                    onChange={this.changePassword.bind(this)}
                                                    className="form-control" name="password"
                                                    required autoComplete="current-password" />



                                            </div>

                                        </div> */}

                                        <div className="form-group row m-t-20">
                                            <div className="col-sm-12">
                                                <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                    <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                            <div className="col-sm-12 text-right">
                                                {/* <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', backgroundColor: this.state.faculdade != null ? this.state.faculdade.primaria : '' }} type="submit">Entrar</button> */}
                                                <DefaultButton
                                                    type="submit"
                                                    bg="primary"
                                                    width='100%'
                                                    height='3rem'
                                                    style={{marginTop:'15px'}}
                                                    text="Entrar"
                                                    loadingtext="Entrando..."
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>

                                                {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                                                {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-center" style={{marginTop:20}}>
                                    <p style={{fontSize:14}}>© {moment().format('YYYY')} OnTest</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block" style={{ overflow: 'hidden', backgroundImage: `url(${BgVestibular})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(Login);
