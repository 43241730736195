// Library Imports
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { RiArrowGoBackLine, RiSendPlaneLine } from 'react-icons/ri';

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post, put } from "../../Services/api";
import removeHtmlTags from "../../Auxiliar/removeHtmlTags";
import DefaultButton from '../ReusableComponents/DefaultButton';

// Components Imports
import { FaBackspace, FaBriefcase, FaCalendar, FaChartLine, FaMortarPestle, FaRegArrowAltCircleRight, FaSchool, FaTags, FaUser, FaUserCircle } from "react-icons/fa";
import Attention from "../ReusableComponents/Attention";
import { CircularProgress } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { MdEdit, MdSaveAlt } from "react-icons/md";
import EditarQuestao from "../CategoriasQuestoes/EditarQuestao";

const ShowAvaliarQuestoes = ({ match, token, user, history }) => {
  const [questao, setQuestao] = useState({
    name: "",
    alternativas: [],
    user: {},
  });
  const [demanda, setDemanda] = useState({});
  const [statusMsg, setStatusMsg] = useState('');
  const [addCommentMsg, setAddCommentMsg] = useState('');


  const [comentarios, setComentarios] = useState([]);
  const [novoComentario, setNovoComentario] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [loadingAddComent, setLoadingAddComment] = useState(false);
  const dispatch = useDispatch();

  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditQuestaoModal, setShowEditQuestaoModal] = useState(false);
  const [showEditAlternativaModal, setShowEditAlternativaModal] =
    useState(false);
  const [currentAlternativaIndex, setCurrentAlternativaIndex] = useState(null);
  const [currentAlternativaValue, setCurrentAlternativaValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchQuestao()]);
    };
    fetchData();
  }, []);

  const fetchQuestao = async () => {
    const { id } = match.params;
    try {
      const resp = await get(`api/faculdade/get_questao_review/${id}`, token);
      setQuestao(resp.questao || {});
      setDemanda(resp.demanda || {});
      if (resp.questao.status_aprovacao && (resp.questao.status_aprovacao.status == 'aprovado' || resp.questao.status_aprovacao.status == 'recusado')) {
        setStatus(resp.questao.status_aprovacao.status)
      }

      const comentarios = resp.questao.comentarios.map(({ comentario, user_name, created_at, user }) => ({
        comentario,
        user_name: user ? user.name : user_name,
        created_at,
      }));
      setComentarios(comentarios);
    } finally {
      setIsLoading(false);
    }
  };


  const editStatusQuestao = async (status) => {
    setLoadingStatus(true);
    setStatusMsg('')
    const { id } = match.params;
    let questao_ = questao;


    let data = { edited: false }
    if (questao_.edited == true) {
      data.edited = true;
      data = {...questao_,...data,};
    }
    else {
      document.getElementById('text_enunciado').innerHTML = questao_.name;
      data.enunciado = questao_.name;
      data.content_enunciado = document.getElementById('text_enunciado').textContent;
      data.categoria_id = questao_.categoria.id;

      data.escolaridade = questao.escolaridade;
      data.dificuldade = questao.dificuldade;
      data.type_questao = questao.type_questao;


      data.palavras_chaves = questao.palavras_chaves;
      data.solucao=questao.solucao;

      if (questao_.type_questao == 'simples') {
        data.alternativa_a = questao_.alternativas[0].name;
        data.numero_alternativa = questao_.alternativas.length;
        if (data.numero_alternativa >= 2) data.alternativa_b = questao_.alternativas[1].name;
        if (data.numero_alternativa >= 3) data.alternativa_c = questao_.alternativas[2].name;
        if (data.numero_alternativa >= 4) data.alternativa_d = questao_.alternativas[3].name;
        if (data.numero_alternativa >= 5) data.alternativa_e = questao_.alternativas[4].name;

        data.correta = questao_.correta;
      }
      else if (questao_.type_questao == 'conjunto') {
        let questoes = [...questao_.questoes];
        questoes = questoes.map((item, id) => {
          item = { ...item };
          item.numero_alternativa = item.alternativas.length;
          item.alternativa_a = item.alternativas[0].name;
          if (item.numero_alternativa >= 2) item.alternativa_b = item.alternativas[1].name;
          if (item.numero_alternativa >= 3) item.alternativa_c = item.alternativas[2].name;
          if (item.numero_alternativa >= 4) item.alternativa_d = item.alternativas[3].name;
          if (item.numero_alternativa >= 5) item.alternativa_e = item.alternativas[4].name;



          delete item.alternativas;
          item.categoria_id = item.categoria.id;
          delete item.categoria;
          return item;
        });
        data.questoes = questoes;
      }
      else {
        //dissertiva nao precisa de mais nada, apenas enunciado
      }
      data.questao_id = id;
    }
    post(`api/faculdade/update_status_questao/${id}`, { ...data,status }, token).then((resp) => {
      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        dispatch(logout())
        return;
      }
      if (resp.status == false) {
        setStatusMsg(resp.message)
        document.getElementById("avaliacao_header").scrollIntoView();
      }
      else if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        setStatusMsg(erro)
        document.getElementById("avaliacao_header").scrollIntoView();
      }
      else {
        setShowSuccessModal(true);
      }
      setLoadingStatus(false);

    }).catch((e) => {
      console.log(e)
      setLoadingStatus(false);

    });
  };

  const handleAddComentario = async () => {
    const { id } = match.params;
    const newComentario = {
      comentario: novoComentario,
      user_name: user.name,
      created_at: new Date().toISOString(),
    };
    setLoadingAddComment(true);
    setAddCommentMsg('');
    setStatusMsg('')

    post(
      "api/faculdade/store_comentario",
      { questao_id: id, comentario: novoComentario },
      token
    ).then((resp) => {
      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        dispatch(logout())
        return;
      }
      console.log(resp)
      if (resp.status == false) {
        setStatusMsg(resp.message)
        document.getElementById("avaliacao_header").scrollIntoView();
      }
      else if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        setStatusMsg(erro)
        document.getElementById("avaliacao_header").scrollIntoView();
      }
      else {
        setComentarios((prev) => [...prev, newComentario]);
        setNovoComentario("");
      }
      setLoadingAddComment(false);
    }).catch((e) => {
      console.log(e)
      setLoadingAddComment(false);
    });

  };

  const handleQuestaoChange = (value) =>
    setQuestao((prev) => ({ ...prev, name: value }));

  const handleAlternativaChange = (index, value) => {
    setQuestao((prev) => {
      const alternativas = [...prev.alternativas];
      alternativas[index].name = value;
      return { ...prev, alternativas };
    });
  };

  const toggleEditQuestaoModal = () => setShowEditQuestaoModal((prev) => !prev);

  const toggleEditAlternativaModal = (index) => {
    setShowEditAlternativaModal((prev) => !prev);
    setCurrentAlternativaIndex(index);
    setCurrentAlternativaValue(questao.alternativas[index]?.name || "");
  };

  const handleSaveAlternativa = () => {
    handleAlternativaChange(currentAlternativaIndex, currentAlternativaValue);
    toggleEditAlternativaModal(null);
  };

  const editorConfig = {
    readonly: true,
    toolbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  if (isLoading) return <div className="d-flex justify-content-center">
  <CircularProgress />
</div>;

  return (
    <>
      <div className="row">
        <div hidden id="text_enunciado">

        </div>
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-body" id="card">
              <div style={{ float: 'right' }}>
                <DefaultButton
                  bg="secondary"
                  text="Voltar"
                  icon={<RiArrowGoBackLine />}
                  to={`/avaliar-questoes`}
                />
              </div>
              <h4 className="mt-0 header-title">Avaliação de questão</h4>

              <hr />
              <p className="mb-2" style={{ fontSize: 19 }}><strong>Dados da questão</strong></p>

              {questao.user && (
                <p className="mb-2 d-flex align-items-center">
                  <FaUser className="me-2 text-secondary" />
                  <span><strong>Criador:</strong> {questao.user.name}</span>
                </p>
              )}
              <p className="mb-2 d-flex align-items-center">
                <FaCalendar className="me-2 text-secondary" />
                {/* <i className="bi bi-calendar-event-fill me-2 text-secondary"></i> */}
                <span><strong>Data de Criação:</strong> {formatDate(questao.created_at)}</span>
              </p>
              <p className="mb-2 d-flex align-items-center">
                <FaBriefcase className="me-2 text-secondary" />
                {/* <i className="bi bi-briefcase-fill me-2 text-secondary"></i> */}
                <span><strong>Demanda Relacionada:</strong> {demanda.id}</span>
              </p>
              <p className="mb-2 d-flex align-items-center">
                <FaChartLine className="me-2 text-secondary" />
                {/* <i className="bi bi-bar-chart-fill me-2 text-secondary"></i> */}
                <span><strong>Nível de Dificuldade:</strong> {questao.dificuldade}</span>
              </p>
              <p className="mb-2 d-flex align-items-center">
                <FaSchool className="me-2 text-secondary" />
                {/* <i className="bi bi-mortarboard-fill me-2 text-secondary"></i> */}
                <span><strong>Escolaridade:</strong> {questao.escolaridade}</span>
              </p>
              <p className="mb-2 d-flex align-items-center">
                {/* <i className="bi bi-tags-fill me-2 text-secondary"></i> */}
                <FaTags className="me-2 text-secondary" />
                <span>
                  <strong>Palavras-Chaves: </strong>
                  {questao.keywords?.length > 0 ? (
                    questao.keywords.join(", ")
                  ) : (
                    <span>Nenhuma palavra-chave vinculada</span>
                  )}
                </span>
              </p>

            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body" id="card2">
              <h4 className="mt-0 header-title">{questao.type_questao == 'simples' ? 'Questão de Múltipa Escolha' : (questao.type_questao == 'conjunto' ? 'Conjunto de Questões de Múltipa Escolha com Enunciado em Comum' : 'Questão Dissertativa')}</h4>

              <hr />
              {!showModalEdit && <>
                {questao.type_questao == 'simples' && <div >
                  {/* <p style={{ color: 'black' }}><b>Questão 1:</b></p> */}
                  <p style={{ color: 'black' }}><b>Enunciado:</b></p>
                  <div dangerouslySetInnerHTML={{ __html: questao.name }}>

                  </div>
                  <p style={{ color: 'black' }}><b>Resposta: </b> {questao.correta})</p>

                  <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                  <div style={{ display: 'flex', marginTop: 10 }}>
                    <div style={{ width: '10%' }}>
                      <p style={{ color: questao.correta == 'a' ? 'green' : 'black', fontWeight: questao.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                    </div>
                    <div style={{ width: '90%' }}>

                      <div dangerouslySetInnerHTML={{ __html: questao.alternativas[0].name }}>

                      </div>
                    </div>

                  </div>
                  {questao.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                    <div style={{ width: '10%' }}>
                      <p style={{ color: questao.correta == 'b' ? 'green' : 'black', fontWeight: questao.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                    </div>
                    <div style={{ width: '90%' }}>

                      <div dangerouslySetInnerHTML={{ __html: questao.alternativas[1].name }}>

                      </div>
                    </div>

                  </div>}
                  {questao.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                    <div style={{ width: '10%' }}>
                      <p style={{ color: questao.correta == 'c' ? 'green' : 'black', fontWeight: questao.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                    </div>
                    <div style={{ width: '90%' }}>

                      <div dangerouslySetInnerHTML={{ __html: questao.alternativas[2].name }}>

                      </div>
                    </div>

                  </div>}
                  {questao.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                    <div style={{ width: '10%' }}>
                      <p style={{ color: questao.correta == 'd' ? 'green' : 'black', fontWeight: questao.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                    </div>
                    <div style={{ width: '90%' }}>

                      <div dangerouslySetInnerHTML={{ __html: questao.alternativas[3].name }}>

                      </div>
                    </div>

                  </div>}

                  {questao.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                    <div style={{ width: '10%' }}>
                      <p style={{ color: questao.correta == 'e' ? 'green' : 'black', fontWeight: questao.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                    </div>
                    <div style={{ width: '90%' }}>

                      <div dangerouslySetInnerHTML={{ __html: questao.alternativas[4].name }}>

                      </div>
                    </div>

                  </div>}

                </div>}
                {questao.type_questao == 'conjunto' &&
                  <div>
                    <p style={{ color: 'black' }}><b>Enunciado geral:</b></p>

                    <div dangerouslySetInnerHTML={{ __html: questao.name }}>

                    </div>

                    {questao.questoes.map((item2, id2) => (<div key={id2}>
                      <hr />
                      <p style={{ color: 'black' }}><b>Questão {id2 + 1}:</b></p>
                      <p style={{ color: 'black' }}><b>Resposta: {item2.correta})</b></p>

                      <div dangerouslySetInnerHTML={{ __html: item2.name }}>

                      </div>
                      <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <p style={{ color: item2.correta == 'a' ? 'green' : 'black', fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                          <div dangerouslySetInnerHTML={{ __html: item2.alternativas[0].name }}>

                          </div>
                        </div>

                      </div>
                      {item2.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <p style={{ color: item2.correta == 'b' ? 'green' : 'black', fontWeight: item2.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                          <div dangerouslySetInnerHTML={{ __html: item2.alternativas[1].name }}>

                          </div>
                        </div>

                      </div>}
                      {item2.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <p style={{ color: item2.correta == 'c' ? 'green' : 'black', fontWeight: item2.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                          <div dangerouslySetInnerHTML={{ __html: item2.alternativas[2].name }}>

                          </div>
                        </div>

                      </div>}
                      {item2.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <p style={{ color: item2.correta == 'd' ? 'green' : 'black', fontWeight: item2.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                          <div dangerouslySetInnerHTML={{ __html: item2.alternativas[3].name }}>

                          </div>
                        </div>

                      </div>}

                      {item2.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <p style={{ color: item2.correta == 'e' ? 'green' : 'black', fontWeight: item2.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                          <div dangerouslySetInnerHTML={{ __html: item2.alternativas[4].name }}>

                          </div>
                        </div>

                      </div>}
                      {<div className="">
                <p style={{ color: 'black' }}><b>Solução:</b></p>

                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.solucao ?? '-' }}>

                </div>
            </div>}
                    </div>))}

                  </div>
                }
                {questao.type_questao == 'dissertativa' && <div >
                  {/* <p style={{ color: 'black' }}><b>Questão 1:</b></p> */}
                  <p style={{ color: 'black' }}><b>Enunciado:</b></p>
                  <div dangerouslySetInnerHTML={{ __html: questao.name }}>

                  </div>
                </div>

                }
                {questao.type_questao != 'conjunto' && <div className="">
                <p style={{ color: 'black' }}><b>Solução:</b></p>

                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.solucao ?? '-' }}>

                </div>
            </div>}
                {/* {showModalEdit && <ModalCadastroQuestao
                salvarQuestao={(e) => {
                  console.log(e)
                  setShowModalEdit(false);
                  // editQuestao(e)
                  e = { ...questao, ...e }
                  e.edited = true;

                  setQuestao(e)
                }}
                questaoSelect={questao}
                index_select={1}
                setShowModal={(e) => setShowModalEdit(e)} showModal={showModalEdit} categoria={questao.categoria} />} */}
                <div className="d-flex justify-content-end mt-2">

                  <DefaultButton
                    bg="info"
                    className="mt-2"
                    text="Alterar questão"
                    icon={<MdEdit />}
                    onClick={() => {
                      setShowModalEdit(true);

                    }}
                  >

                  </DefaultButton>
                </div>
              </>}
              {showModalEdit && <EditarQuestao
                saveQuestion={(data) => {
                  setQuestao({...questao,...data,edited:true})
                  setShowModalEdit(false);
                }}
                demanda={demanda} questao={questao} evaluation location={{ search: "" }} match={{ params: { id: questao.id } }} />}

            </div>
          </div>


          <div id="avaliacao_header"  className="card mb-4">
            <div className="card-body" id="card3">
              <h4  className="mt-0 header-title">Avaliação</h4>
              {statusMsg != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setStatusMsg('')}></button>


                  <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{statusMsg}</p>

                </div>
                <br />

              </div>}
              <hr />
              <p className="mb-2" style={{ fontSize: 19 }}><strong>Comentários</strong></p>
              {isCommentsLoading ? (
                <p className="text-muted text-center">Carregando comentários...</p>
              ) : (
                <ul className="list-unstyled">
                  {comentarios.length == 0 && <li>Nenhum comentário adicionado</li>}
                  {comentarios.map((comentario, index) => (
                    <li
                      key={index}
                      className="d-flex align-items-center mb-3"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="me-3">
                        <FaUserCircle className="fs-4 text-muted" />
                      </div>
                      <div className="flex-grow-1">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>
                              Criado em: {formatDate(comentario.created_at)}
                            </Tooltip>
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <strong className="text-muted me-2">
                              {comentario.user_name}
                            </strong>
                            <small className="text-muted">
                              {formatDate(comentario.created_at)}
                            </small>
                          </div>
                        </OverlayTrigger>
                        <p className="mb-0 text-dark">{comentario.comentario}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}

              <div className="mt-4">
                <Card.Title className="fw-semibold fs-5 text-dark mb-3">
                  Adicionar Comentário <Attention />
                </Card.Title>
                <textarea
                  className="form-control mb-2"
                  value={novoComentario}
                  onChange={(e) => setNovoComentario(e.target.value)}
                  placeholder="Escreva seu comentário aqui..."
                />
                {addCommentMsg && <p style={{ color: 'red', fontSize: 14 }}>O campo Comentário é obrigatório</p>}
                <div className="d-flex justify-content-end mt-2">
                  {loadingAddComent == false ? <DefaultButton
                    bg="info"
                    icon={<MdSaveAlt />}
                    text="Salvar comentário"
                    onClick={handleAddComentario}
                  >

                  </DefaultButton> : <CircularProgress />}
                </div>

              </div>
              <hr />
              <p className="mb-2" style={{ fontSize: 19 }}><strong>Status</strong> <Attention /> </p>
              <small>Caso a questão tenha sido editada, ela só será salva ao enviar a avaliação.</small>
              {["aprovado", "recusado"].map((value) => (
                <div className="form-check" key={value}>
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      value={value}
                      checked={status == value}
                      onChange={() => setStatus(value)}
                    />
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </label>
                </div>
              ))}
              {/* {statusMsg && <p style={{ color: 'red', fontSize: 14 }}>O campo Status é obrigatório</p>} */}
              <div className="w100 inline-flex jcsb mt-2" >
                <DefaultButton
                  bg="secondary"
                  text="Voltar"
                  icon={<RiArrowGoBackLine />}
                  to={`/avaliar-questoes`}
                />
                {loadingStatus == false ? <DefaultButton
                  bg="confirm"
                  text="Enviar Avaliação"

                  icon={<RiSendPlaneLine />}

                  onClick={() => editStatusQuestao(status)}
                >
                </DefaultButton> : <CircularProgress />}
              </div>

            </div>
          </div>


        </div>
      </div>


      <div className="container mt-4">

        {/* <SuccessModal
          show={showSuccessModal}
          handleClose={() => setShowSuccessModal(false)}
        /> */}
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            setShowSuccessModal(false)
            // this.delete_banner(this.state.banner_to_delete);
          }}
          onCancel={() => {
            setShowSuccessModal(false)

          }}
          show={showSuccessModal}
          confirmBtnText='Ok'
          confirmBtnBsStyle="success"

        >
          Questão avaliada com sucesso!
        </SweetAlert>
        {/* <EditModal
          show={showEditQuestaoModal}
          handleClose={toggleEditQuestaoModal}
          title="Editar Enunciado da Questão"
          value={questao.name}
          onChange={handleQuestaoChange}
          onSave={toggleEditQuestaoModal}
        />
        <EditModal
          show={showEditAlternativaModal}
          handleClose={toggleEditAlternativaModal}
          title="Editar Alternativa"
          value={currentAlternativaValue}
          onChange={setCurrentAlternativaValue}
          onSave={handleSaveAlternativa}
        /> */}
      </div>
    </>

  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(
  withRouter(ShowAvaliarQuestoes)
);
