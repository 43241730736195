import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaDownload, FaListAlt, FaPlus } from "react-icons/fa";
import { MdFileDownload, MdVisibility } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Search from "../ReusableComponents/Search";
import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from "@material-ui/lab/Pagination";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { API_URL, URL } from "../../variables";
import { connect } from "react-redux";
import { BsFillEyeFill } from "react-icons/bs";
import ModalCorrecaoIA from "./ModalCorrecaoIA";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const ResultadosRedacaoIA = (props) => {
  const { redacao_id, redacao_name, processo_id, token } = props;
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(0);


  const [loading_screen, setLoadingScreen] = useState(true);
  const [loading_redacoes, setLoadingredacoes] = useState(false);
  const [search_oficial, setSearchOficial] = useState("");
  const [redacoes, setredacoes] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });
  const [selectedRedacoes, setSelectedRedacoes] = useState([]);
  const [isLoadingSelected, setIsLoadingSelected] = useState(false);
  const [isLoadingBaixar, setIsLoadingBaixar] = useState(false);
  const [show_warning, setShowWarning] = useState(false);
  const [redacoes_id, setRedacoesId] = useState(null);
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [message_zip, setMessageZip] = useState('');
  const [loading_zip, setLoadingZip] = useState(false);
  const timerRefZip = useRef();
  const Getredacoes = async (page, search) => {
    setLoadingredacoes(true)
    let response = await Request.Get(
      `faculdade/get_resultados_redacao/${redacao_id}?page=${page}&search=${search}`
    );
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      dispatch(logout());
      return;
    }
    if (response.pagination.current_page == page) {
      setredacoes(response.redacoes);
      setPagination(response.pagination);
      setLoadingScreen(false);
      setLoadingredacoes(false);
    }
  };

  const DeleteRedacao = async (redacoes_id) => {
    let response = await Request.Delete(
      `faculdade/desvincular_redacao/${redacoes_id}`
    );
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      dispatch(logout());
      return;
    }
    Getredacoes(pagination.current_page, search_oficial);
  };

  useEffect(() => {
    Getredacoes(1, search_oficial);
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(timerRefZip.current)
    };
  }, []);

  const handleSelectRedacao = (redacao) => {
    setSelectedRedacoes((prev) => {
      const isSelected = prev.includes(redacao.id);
      if (isSelected) {
        return prev.filter((id) => id !== redacao.id);
      } else {
        return [...prev, redacao.id];
      }
    });
  };




  const handleCorrigirSelecionadas = async (type = 'selected') => {
    console.log(selectedRedacoes)
    if (selectedRedacoes.length === 0 && type != 'all') {
      // alert("Por favor, selecione pelo menos uma redação");
      return;
    }
    setIsLoadingSelected(true);

    // const selectedRedacoesData = redacoes.filter((redacao) =>
    //   selectedRedacoes.includes(redacao.id)
    // );

    const data = {
      redacao_id: redacao_id,
      redacoes: type == 'all' ? [] : selectedRedacoes,
      type: type
      // redacoes: selectedRedacoesData.map((redacao) => ({
      //   id: redacao.id,
      //   texto: redacao.texto,
      //   nome: redacao.titulo,
      //   nome_candidato: redacao.user.name,
      //   numero_inscricao: redacao.user.numero_inscricao,
      //   cpf: redacao.user.cpf,
      // })),
    };

    try {
      setIsLoadingSelected(true);
      const response = await axios.post(`${API_URL}faculdade/corrigir-redacoes`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });
      let resp = response.data
      if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        console.log(erro);
      }
      else {
        setTab(1);
        setSelectedRedacoes([]);
      }
      setIsLoadingSelected(false);
    } catch (error) {
      setIsLoadingSelected(false);
    }
  };




  const handleBaixarRedacoesSalvas = async () => {
    setIsLoadingBaixar(true);

    try {

      let response = await Request.Post(`faculdade/download_correcoes_ia/${redacao_id}`);
      console.log(response);
      if (response === undefined) return;
      if (response['message'] == 'Unauthenticated.') {
        dispatch(logout());
        return;
      }
      // else if(response['status']==undefined){
      //     setLoadingZip(false);

      //     saveAs(response,'provas_processo_'+processo_name+'.zip');
      // }
      else {
        // setLoadingZip(false);
        // setMessageZip('');


        if (response['status'] == true) {
          document.getElementById('download_link').href = response['path'];
          document.getElementById('download_link').click();
          setLoadingZip(false);
          setMessageZip('');

          return;
        }
        else if (response['status'] != 'Error') {
          setMessageZip(response['message']);
        }
        timerRefZip.current = setTimeout(() => {
          handleBaixarRedacoesSalvas();
        }, 20000);


      }

    } catch (error) {
      setIsLoadingBaixar(false);
    }
  };

  const handleVisualizarCorrecao = (redacao) => {
    props.onVisualizarCorrecao(
      redacao.id,
    );
  };

  let pesquisarTimeout = null;
  const history = useHistory();
  console.log(tab)
  return (
    <div className="row">
      <div className="col-12">
        {loading_screen == false && (
          <div>
            {/* <p className="text-muted">
              Aqui ficam as respostas da redação "{redacao_name}"
            </p> */}

            <p className="text-muted">Aqui ficam as correções da redação "{redacao_name}" realizadas por inteligência artificial</p>

            <a id="download_link" target="_blank"></a>
            {tab == 0 && <div className="flex jcsb flex-wrap mb-1">
              <Search
                placeholder="Pesquisar pelo nome/cpf/número de inscrição do candidato"
                ValueChange={(value) => {
                  clearTimeout(pesquisarTimeout);
                  setSearchOficial(value);

                  pesquisarTimeout = setTimeout(() => {
                    let page = { ...pagination };
                    page.current_page = 1;
                    setPagination(pagination);
                    setLoadingredacoes(true);

                    Getredacoes(1, value);
                  }, 500);
                }}
              />

              <div className="col-12 mt-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span>

                  <Button
                    variant="success"
                    style={{ width: 'auto', marginRight: 10 }}
                    className="flex align-center jcc"
                    onClick={() => handleCorrigirSelecionadas()}
                    disabled={isLoadingSelected}
                  >
                    <FaPlus style={{ marginRight: 10 }} />
                    {isLoadingSelected ? (
                      <>
                        {"Iniciando correção..."
                        }
                        <CircularProgress size={20} style={{ marginLeft: 10 }} />
                      </>
                    ) : (
                      "Corrigir Selecionadas"
                    )}
                  </Button>
                </span>
                <span>

                  <Button
                    variant="success"
                    style={{ width: 'auto', marginRight: 10 }}

                    className="flex align-center jcc"
                    onClick={() => {
                      handleCorrigirSelecionadas('all');
                    }}
                    disabled={isLoadingSelected}
                  >
                    <FaListAlt style={{ marginRight: 10 }} />
                    {isLoadingSelected ? (
                      <>
                        {"Iniciando correção..."
                        }
                        <CircularProgress size={20} style={{ marginLeft: 10 }} />
                      </>
                    ) : (
                      "Corrigir Todas as Redações"
                    )}
                  </Button>
                </span>
                <span>

                  {loading_zip == false && <span onClick={() => {
                    if (loading_zip == false) {
                      setLoadingZip(true);
                      setMessageZip('Criando .zip para download');
                      handleBaixarRedacoesSalvas();
                    }
                    else {

                    }
                  }} style={{ textDecoration: "none", marginRight: 5 }}>
                    {<Button variant="success" className="flex align-center jcc"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar correções</p></Button>}

                  </span>}
                  {loading_zip == true && <div style={{ flexBasis: "50%", paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                    {/* <div className="spinner-border text-primary" role="status"  >
                                                      </div> */}
                    <CircularProgress size={15} />
                  </div>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                      <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                    </div>
                  </div>}

                  
                </span>

              </div>

            </div>}
            {<div className={"d-flex mt-3 mb-3 " + (tab == 0 ? 'justify-content-end' : 'justify-content-start')}>
              <a
                href="#"
                style={{ border: 0, textDecoration: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  if (tab == 1) {
                    Getredacoes(pagination.current_page, search_oficial);
                  }
                  setTab(tab == 0 ? 1 : 0)
                }}
              >
                {tab == 0 ? <>
                  Ir Para Progresso da Correção
                  <ArrowRight style={{ marginLeft: 10 }} />
                </> : <>
                  <ArrowLeft style={{ marginRight: 10 }} />
                  Ir Para Lista de Correções
                </>}

              </a>
            </div>}
            {tab == 1 && <ModalCorrecaoIA onFinish={() => {
              // setLoadingcandidatos(true);
              // Getcandidatos(1, search_oficial);
            }} showModal={showModal} setShowModal={(e) => setShowModal(e)} processo_id={processo_id} redacao_name={redacao_name} />}

            {tab == 0 && <>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table id="tech-companies-1" className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          {/* <input
                          type="checkbox"
                          onChange={handleSelectAllRedacoes}
                          checked={selectedRedacoes.length === redacoes.length}
                        /> */}
                        </th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>CPF</th>
                        <th>Número de Inscrição</th>

                        <th>Status</th>
                        <th data-priority="3">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading_redacoes == false &&
                        redacoes.map((item, id) => (
                          <tr key={id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRedacoes.includes(item.id)}
                                onChange={() => handleSelectRedacao(item)}
                              />
                            </td>
                            <td>{item.user.name}</td>
                            <td>{item.user.email}</td>
                            <td>{item.user.cpf}</td>
                            <td>{item.user.numero_inscricao}</td>

                            {item.statusCorrecao == "corrigida" && (
                              <td style={{ color: "green" }}>{"Corrigida"}</td>
                            )}
                            {item.statusCorrecao == "nao-corrigida" && (
                              <td style={{ color: "darkgoldenrod" }}>
                                {"Não Corrigida"}
                              </td>
                            )}

                            {item.processo_resultado.status == "Ausente" && (
                              <td style={{ color: "ref" }}>{"Ausente"}</td>
                            )}
                            <td>


                              <span
                                onClick={() => handleVisualizarCorrecao(item)}

                                className="inline-flex align-center jcc bg-warning icon"
                                style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                title={"Ver correção da IA"}
                              >
                                <BsFillEyeFill size={17} color="white" />
                              </span>

                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {loading_redacoes == true && (
                    <div className="row" style={{ margin: 0 }}>
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <CircularProgress />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {loading_redacoes == false && redacoes.length == 0 && (
                <div className="row w-100">
                  <div className="col-12">
                    <p style={{ textAlign: "center" }}>
                      Nenhuma redação encontrada
                    </p>
                  </div>
                </div>
              )}

              <Pagination
                className={pagination.last_page == 1 ? "hide" : ""}
                style={{ display: "flex", justifyContent: "flex-end" }}
                count={pagination.last_page}
                value={pagination.current_page}
                onChange={(_, value) => {
                  setLoadingredacoes(true);
                  let page = { ...pagination };
                  page.current_page = value;
                  setPagination(pagination);
                  Getredacoes(value, search_oficial);
                }}
              />
            </>}

          </div>
        )}

        {loading_screen == true && (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <CircularProgress />
            </div>
          </div>
        )}

        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            setShowWarning(false);
            setLoadingredacoes(true);
            DeleteRedacao(redacoes_id);
          }}
          onCancel={() => {
            setShowWarning(false);
          }}
          show={show_warning}
          confirmBtnText="Sim, desejo desvincular"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Ao desvincular este redacao, ele não terá mais acesso a este processo
        </SweetAlert>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(ResultadosRedacaoIA);
