import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import moment from "moment";

const ModalQuestao = props => {
    const { questao, showModal, setShowModal, questao_number } = props;
    console.log(questao)

    const showQuestion = useCallback(()=>{
        return questao!=null && (<>
            <div style={{ marginBottom: "10px" }}>
                <strong>Tipo: </strong><span>{questao.type_questao == 'conjunto' ? ('Conjunto de questões - ' + questao.questoes.length + (questao.questoes.length == 1 ? ' questão' : ' questões')) :
                    (questao.type_questao == 'simples' ? 'Questão única' : 'Questão dissertativa')}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
                <strong>Disciplina: </strong><span>{questao.categoria.name}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
                <strong>Dificuldade: </strong><span>{questao.dificuldade}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
                <strong>Escolaridade: </strong><span>{questao.escolaridade}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
                <strong>Gerada em: </strong><span>{moment(questao.created_at).format('DD/MM/YYYY HH:mm')}</span>
            </div>

            <div
                className="solucao"
                style={{ marginBottom: "10px" }}
            >
                <strong>{'Palavras chaves'}:</strong>{" "}{questao.keywords?.length==0 ? '-':''}
                {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                    {questao.keywords?.map((item2, id2) => (
                        <div key={id2} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                            <p style={{ marginBottom: 0, textAlign: 'center' }}>{item2}</p>
                        </div>
                    ))}
                </div>}
            </div>

            {(questao.type_questao == 'simples' || questao.id == undefined) && <>
                <div className="">
                    <p style={{ color: 'black' }}><b>Enunciado:</b></p>

                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.name }}>

                    </div>
                    <hr />
                    <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{ color: questao.correta == 'a' ? 'green' : 'black', fontWeight: questao.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.alternativas[0].name }}>

                            </div>
                        </div>

                    </div>
                    {questao.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{ color: questao.correta == 'b' ? 'green' : 'black', fontWeight: questao.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.alternativas[1].name }}>

                            </div>
                        </div>

                    </div>}
                    {questao.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{ color: questao.correta == 'c' ? 'green' : 'black', fontWeight: questao.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.alternativas[2].name }}>

                            </div>
                        </div>

                    </div>}
                    {questao.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{ color: questao.correta == 'd' ? 'green' : 'black', fontWeight: questao.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.alternativas[3].name }}>

                            </div>
                        </div>

                    </div>}

                    {questao.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{ color: questao.correta == 'e' ? 'green' : 'black', fontWeight: questao.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.alternativas[4].name }}>

                            </div>
                        </div>

                    </div>}

                </div>

            </>}


            {questao.type_questao == 'conjunto' && <>
                <div className="custom_scrollbar">
                    <p style={{ color: 'black' }}><b>Enunciado geral:</b></p>

                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.name }}>

                    </div>

                    {questao.questoes.map((item2, id2) => (<div key={id2}>
                        <hr />
                        <p style={{ color: 'black' }}><b>Questão {id2 + 1}:</b></p>

                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.name }}>

                        </div>
                        <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: item2.correta == 'a' ? 'green' : 'black', fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[0].name }}>

                                </div>
                            </div>

                        </div>
                        {item2.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: item2.correta == 'b' ? 'green' : 'black', fontWeight: item2.correta == 'b' ? 'bold' : 'normal' }}>b)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[1].name }}>

                                </div>
                            </div>

                        </div>}
                        {item2.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: item2.correta == 'c' ? 'green' : 'black', fontWeight: item2.correta == 'c' ? 'bold' : 'normal' }}>c)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[2].name }}>

                                </div>
                            </div>

                        </div>}
                        {item2.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: item2.correta == 'd' ? 'green' : 'black', fontWeight: item2.correta == 'd' ? 'bold' : 'normal' }}>d)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[3].name }}>

                                </div>
                            </div>

                        </div>}

                        {item2.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: item2.correta == 'e' ? 'green' : 'black', fontWeight: item2.correta == 'e' ? 'bold' : 'normal' }}>e)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[4].name }}>

                                </div>
                            </div>

                        </div>}
                        {<div className="">
                <p style={{ color: 'black' }}><b>Solução:</b></p>

                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.solucao ?? '-' }}>

                </div>
            </div>}
                    </div>))}

                </div>

            </>}

            {(questao.type_questao == 'dissertativa') && <>
                <div className="">
                    <p style={{ color: 'black' }}><b>Enunciado:</b></p>

                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.name }}>

                    </div>
                </div>

            </>}
            {questao.type_questao != 'conjunto' && <div className="">
                <p style={{ color: 'black' }}><b>Solução:</b></p>

                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: questao.solucao ?? '-' }}>

                </div>
            </div>}
        </>)
    },[questao, questao_number]);
    return (
        <>
        {questao != null && showModal!=undefined && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Questão de {questao.categoria?.name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                {showQuestion()}

            </Modal.Body>
        </Modal>}
        {questao != null && showModal==undefined &&
showQuestion()
}
        </>


    );
}

export default ModalQuestao;