// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { FaStar, FaUserCircle } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post } from "../../Services/api";
import Search from "../ReusableComponents/Search";
import { Pagination } from '@material-ui/lab';
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { MdSaveAlt } from "react-icons/md";
import { RiSendPlaneLine } from "react-icons/ri";
import SweetAlert from "react-bootstrap-sweetalert";

const SuccessModal = ({ show, handleClose, handleTableUpdate }) => (
  <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            handleClose();
            // this.delete_banner(this.state.banner_to_delete);
          }}
          onCancel={() => {
            handleClose();

          }}
          show={show}
          confirmBtnText='Ok'
          confirmBtnBsStyle="success"

        >
          Questão avaliada com sucesso!
        </SweetAlert>
);

class AvaliarQuestoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      demandas: [],
      questoes: [],
      loading: true,
      redirect: false,
      categoria: null,
      demanda: null,
      pagination: null,
      isModalOpen: false,
      novoComentario: "",
      status: "",
      comentarios: [],
      isCommentsLoading: false,
      showSuccessModal: false,
      questao: {},
      isModalLoading: false,
      search: '',
      statusMsg:'',
      loadingStatus:false,
      loadingAddComent:false,
      statusFilter:'all'
    };
  }

  componentDidMount() {
    this.fetchQuestoes();
  }

  fetchQuestoes = async (page = 1, search = '') => {
    try {
      const { token } = this.props;
      const resp = await get(
        `api/faculdade/get_all_questoes?search=${search}&page=${page}&status=${this.state.statusFilter}`,
        token
      );
      const questoesWithDemanda = resp.questoes;

      this.setState((prevState) => ({
        questoes: questoesWithDemanda,
        pagination: resp.pagination,
        loading: false
      }));
    } catch (error) {
      this.setState({ redirect: true });
    }
  };

  

  editStatusQuestao = async (status, questao_) => {
      const { token } = this.props;
      const id = questao_.id;
      this.setState({loadingStatus:true,statusMsg:''})
  
  
      let data = { edited: false }
      if (questao_.edited == true) {
        data.edited = true;
      }
      console.log(questao_, status)
      document.getElementById('text_enunciado').innerHTML = questao_.name;
      data.enunciado = questao_.name;
      data.content_enunciado = document.getElementById('text_enunciado').textContent;
      data.categoria_id = questao_.categoria.id;
  
      data.escolaridade = questao_.escolaridade;
      data.dificuldade = questao_.dificuldade;
      data.type_questao = questao_.type_questao;
  
  
      data.palavras_chaves = questao_.palavras_chaves;
  
      if (questao_.type_questao == 'simples') {
        data.alternativa_a = questao_.alternativas[0].name;
        data.numero_alternativa = questao_.alternativas.length;
        if (data.numero_alternativa >= 2) data.alternativa_b = questao_.alternativas[1].name;
        if (data.numero_alternativa >= 3) data.alternativa_c = questao_.alternativas[2].name;
        if (data.numero_alternativa >= 4) data.alternativa_d = questao_.alternativas[3].name;
        if (data.numero_alternativa >= 5) data.alternativa_e = questao_.alternativas[4].name;
  
        data.correta = questao_.correta;
      }
      else if (questao_.type_questao == 'conjunto') {
        let questoes = [...questao_.questoes];
        console.log(questoes)
        questoes = questoes.map((item, id) => {
          item = { ...item };
          item.numero_alternativa = item.alternativas.length;
          item.alternativa_a = item.alternativas[0].name;
          if (item.numero_alternativa >= 2) item.alternativa_b = item.alternativas[1].name;
          if (item.numero_alternativa >= 3) item.alternativa_c = item.alternativas[2].name;
          if (item.numero_alternativa >= 4) item.alternativa_d = item.alternativas[3].name;
          if (item.numero_alternativa >= 5) item.alternativa_e = item.alternativas[4].name;
  
  
  
          delete item.alternativas;
          item.categoria_id = item.categoria.id;
          delete item.categoria;
          return item;
        });
        data.questoes = questoes;
      }
      else {
        //dissertiva nao precisa de mais nada, apenas enunciado
      }
      data.questao_id = id;
      console.log({ status, ...data })
      post(`api/faculdade/update_status_questao/${id}`, { status, ...data }, token).then((resp) => {
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          dispatchEvent(logout());
          this.props.logout();
          return;
        }
        if (resp.status==false) {
          this.setState({statusMsg:resp.message,loadingStatus:false})
          document.getElementById("avaliacao_header").scrollIntoView();

        }
        else if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          this.setState({statusMsg:erro,loadingStatus:false})
          document.getElementById("avaliacao_header").scrollIntoView();
        }
        else {
          let questoes = this.state.questoes.map((item)=>{
            if(item.id==questao_.id){
              item.statusAprovacao=status;
              if(questao_.id==item.id){
                questao_={...item};
              }
            }
            return item;
          });
          this.setState({statusMsg:'',loadingStatus:false,showSuccessModal:true,questoes,questao:questao_})
    this.closeModal();

        }
  
      }).catch((e) => {
        console.log(e)
        this.setState({loadingStatus:false})
  
      });
  };

  handleAddComentario = async (questaoId) => {
    const { token, user } = this.props;
    const { novoComentario } = this.state;
    this.setState({loadingAddComent:true,statusMsg:''});
    try {
      let resp = await post(
        "api/faculdade/store_comentario",
        { questao_id: questaoId, comentario: novoComentario },
        token
      );
      console.log(resp)
      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        this.props.logout()
        return;
      }
      if (resp.status==false) {
        this.setState({statusMsg:resp.message,loadingAddComent:false});
        document.getElementById("avaliacao_header").scrollIntoView();

      }
      else if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        this.setState({statusMsg:erro,loadingAddComent:false})
        document.getElementById("avaliacao_header").scrollIntoView();
      }
      else {
        let questao = this.state.questao;
        let questoes = this.state.questoes.map((item)=>{
          if(item.id==questaoId){
            item.comentarios = [
              ...item.comentarios,
              {
                comentario: novoComentario,
                user_name: user.name,
                created_at: new Date().toISOString(),
              },
            ]
            if(questao.id==item.id){
              questao={...item};
            }
          }
          return item;
        });
        this.setState((prevState) => ({
          questoes,
          questao,
          novoComentario: "",
          loadingAddComent:false
        }));
      }
      
    } catch (error) { }
  };

  openModal = (questao) => {
    console.log(questao)
    this.setState({ isModalOpen: true, questao:questao,status:questao.statusAprovacao ?? '', isModalLoading: false });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleNovoComentarioChange = (event) => {
    this.setState({ novoComentario: event.target.value });
  };

  handleStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  handleSubmitStatus = () => {
    this.editStatusQuestao(this.state.status, this.state.questao);
  };

  handleSubmitComentario = () => {
    this.handleAddComentario(this.state.questao.id);
  };
  handleTableUpdate = () => {
  };

  render() {
    const {
      redirect,
      loading,
      isModalOpen,
      isCommentsLoading,
      questao,
      novoComentario,
      status,
      showSuccessModal,
      isModalLoading,
      statusMsg,
      loadingAddComent,
      loadingStatus,
      statusFilter
    } = this.state;

    const questoesFiltradas = this.state.questoes;

    if (redirect) {
      return <Redirect to="/error" />;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title">Lista de Questões para Avaliação</h4>
              <p>
                Aqui são listados todas as questões pendentes do seu sistema
              </p>
              <div className="row">
                <div className="flex jcsb flex-wrap mb-1">

                  <Search placeholder="Pesquisar pelo enunciado ou nome do elaborador" ValueChange={value => {
                    clearTimeout(this.pesquisarTimeout);
                    this.pesquisarTimeout = setTimeout(() => {
                      let page = { ...this.state.pagination };
                      page.current_page = 1;
                      this.setState({ pagination: page, search: value, loading: true });
                      this.fetchQuestoes(1, value);
                    }, 2000);
                  }} />


                  




                </div>
                <div className="flex flex-wrap">
                  <strong>Status:&nbsp;&nbsp;&nbsp;</strong>  
                  {["pendente","aprovado", "recusado","all"].map((option) => (
                    <div
                      key={option}
                      className="form-check align-items-center mb-2 me-4"
                    >
                      <input
                        type="radio"
                        name={"dsada"+option}
                        id={"dsada"+option}
                        className="form-check-input me-2"
                        value={option}
                        checked={statusFilter === option}
                        onChange={(e)=>this.setState({statusFilter:e.target.value,loading:true},()=>{
                          this.fetchQuestoes(this.state.pagination.page,this.state.search)
                        })}
                      />
                      <label htmlFor={option} className="form-check-label">
                        {option === "pendente"?"Pendentes": (option === "aprovado" ? "Aprovadas" : (option=='recusado'?"Desaprovadas":"Todas"))}
                      </label>
                    </div>
                  ))}
                  </div>

                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div >

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Enunciado</th>
                              <th>Tipo de Questão</th>
                              <th>Disciplina</th>
                              <th>Nome do elaborador</th>
                              <th>Identificador da demanda</th>
                              <th>Status Auditor</th>
                              <th>Data da última atualização</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questoesFiltradas.length === 0 ? (
                              <tr>
                                <td colSpan="8">
                                  {/* <div
                                    className="alert alert-warning"
                                    role="alert"
                                  > */}
                                  Nenhuma questão encontrada.
                                  {/* </div> */}
                                </td>
                              </tr>
                            ) : (
                              questoesFiltradas.map((questao, index) => (
                                <tr key={index}>
                                  <td>{questao.text_questao}</td>
                                  {questao.type_questao == "simples" && (
                                      <td>Múltipla Escolha</td>
                                    )}
                                    {questao.type_questao == "conjunto" && <td>Conjunto de {questao.questoes.length} Questões</td>}

                                      {questao.type_questao == "dissertativa" && 
                                      <td>Dissertativa</td>
                                    }
                                  <td>{questao.categoria.name}</td>
                                  <td>{questao.user.name}</td>

                                  <td>{questao.demanda.id}</td>
                                  <td>{(questao.statusAprovacao==null || questao.statusAprovacao=='pendente')?<span style={{color:'darkgoldenrod'}}>Pendente</span>:(questao.statusAprovacao=='aprovado'?<span style={{color:'green'}}>Aprovado</span>:<span style={{color:'red'}}>Recusado</span>)}</td>
                                  <td>{formatDate(questao.updated_at)}</td>
                                  <td>
                                    <Link
                                      to={`/avaliar-questoes/show/${questao.id}`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-warning icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        title="Gerenciar"
                                      >
                                        <BsFillEyeFill
                                          size={17}
                                          color="white"
                                        />
                                      </span>
                                    </Link>

                                    <span
                                      onClick={() => this.openModal(questao)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-info icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        data-toggle="tooltip"
                                        title="Avaliar Questão"
                                      >
                                        <FaStar color="white" size={17} />
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Pagination
                      className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={this.state.pagination.last_page}
                      value={this.state.pagination.current_page}
                      onChange={(_, value) => {
                        console.log(_, value);
                        let page = { ...this.state.pagination };
                        page.current_page = value;
                        this.setState({ pagination: page, loading: true });
                        this.fetchQuestoes(value, this.state.search);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div hidden id="text_enunciado">

</div>
        <Modal show={isModalOpen} onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5 fw-bold">Avaliar Questão</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isModalLoading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="mb-4" id="avaliacao_header">
                {statusMsg != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({statusMsg:''})}></button>


                  <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{statusMsg}</p>

                </div>
                <br />

              </div>}
                  <h6 className="fw-semibold text-dark mb-3">Comentários</h6>
                  {isCommentsLoading ? (
                    <p className="text-muted text-center">
                      Carregando comentários...
                    </p>
                  ) : (
                    <ul className="list-unstyled">
                      {questao?.comentarios?.length==0 && <li>Nenhum comentário adicionado</li>}
                      {questao?.comentarios?.map((comentario, index) => (
                         <li
                         key={index}
                         className="d-flex align-items-center mb-3"
                         style={{ cursor: "pointer" }}
                       >
                         <div className="me-3">
                           <FaUserCircle className="fs-4 text-muted" />
                         </div>
                         <div className="flex-grow-1">
                           <OverlayTrigger
                             placement="top"
                             overlay={
                               <Tooltip id={`tooltip-${index}`}>
                                 Criado em: {formatDate(comentario.created_at)}
                               </Tooltip>
                             }
                           >
                             <div className="d-flex justify-content-between align-items-center">
                               <strong className="text-muted me-2">
                                 {comentario.user_name}
                               </strong>
                               <small className="text-muted">
                                 {formatDate(comentario.created_at)}
                               </small>
                             </div>
                           </OverlayTrigger>
                           <p className="mb-0 text-dark">{comentario.comentario}</p>
                         </div>
                       </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="mb-4">
                  <h6 className="fw-semibold text-dark mb-3">
                    Adicionar Comentário <Attention />
                  </h6>
                  <textarea
                    className="form-control mb-2"
                    value={novoComentario}
                    onChange={this.handleNovoComentarioChange}
                    placeholder="Escreva seu comentário aqui..."
                  />
                  <div className="d-flex justify-content-end mt-2">
                  {loadingAddComent == false ? <DefaultButton
                    bg="info"
                    icon={<MdSaveAlt/>}
                    text="Salvar comentário"
                    onClick={()=>this.handleSubmitComentario()}
                  >
                    
                  </DefaultButton> : <CircularProgress />}
                  </div>
                </div>

                <div className="mb-4">
                  <h6 className="fw-semibold text-dark mb-3">Avaliar</h6>
                  {["aprovado", "recusado"].map((option) => (
                    <div
                      key={option}
                      className="d-flex align-items-center mb-2"
                    >
                      <input
                        type="radio"
                        id={option}
                        className="form-check-input me-2"
                        value={option}
                        checked={status === option}
                        onChange={this.handleStatusChange}
                      />
                      <label htmlFor={option} className="form-check-label">
                        {option === "aprovado" ? "Aprovar" : "Desaprovar"}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-end">
                  {loadingStatus==false ?<DefaultButton
                                            bg="confirm"
                  text="Enviar Avaliação"

                  icon={<RiSendPlaneLine />}

                  onClick={() => this.handleSubmitStatus()}
                >
                </DefaultButton>:<CircularProgress/>}
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <SuccessModal
          show={showSuccessModal}
          handleClose={() => this.setState({ showSuccessModal: false })}
          handleTableUpdate={this.handleTableUpdate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(AvaliarQuestoes);
