// Library Imports
import React, { useState, useEffect, useCallback } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { URL } from '../../../variables';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/AppActions";
import { CircularProgress } from "@mui/material";


const INITIAL_FORM_DATA = {
  tema: "",
  disciplina: "",
  tipoQuestao: "",
  nivelEscolaridade: "",
  nivelDificuldade: "",
  quantidadeAlternativas: "",
};

const TIPOS_QUESTAO = ["Questão objetiva", "Questão dissertativa"];
const NIVEIS_ESCOLARIDADE = [
  "Ensino Fundamental",
  "Ensino Médio",
  "Ensino Superior",
];
const NIVEIS_DIFICULDADE = ["Fácil", "Médio", "Difícil"];

const ContextForm = ({ onSubmit, token, id, initialData,setCategoriaId }) => {
  const [formData, setFormData] = useState(initialData);
  const [disciplinas, setDisciplinas] = useState([]);
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();


  const fetchDisciplina = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${URL}api/faculdade/get_categorias?all=1`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const resp = await response.json();

      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        dispatch(logout());
        return;
      }
      if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        console.log(erro);
        // history.push('/error')
        //   setTimeout(()=>{
        //     this.get_processo();
        //   });
      }
      else {
        console.log(resp)
        const nomesCategorias = resp.categorias;
        setDisciplinas(nomesCategorias);
        setFormData((prev) => ({ ...prev, disciplina: nomesCategorias.filter((item) => item.id == id)[0].name }));

      }
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar disciplinas:", error);
    }
  };

  useEffect(() => {
    fetchDisciplina(params.id);
  }, []);


  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === "disciplina") {
        let value1 = disciplinas.filter((item) => item.id == value)[0]
        setFormData((prev) => ({
          ...prev,
          disciplina_id: value1?.id,
          [name]: value1?.name,
        }));
        console.log(value)
        setCategoriaId(value)
        
      } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    },
    [disciplinas]
  );

 

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(formData);
      setFormData(INITIAL_FORM_DATA);
      localStorage.removeItem("currentQuestionId");
    },
    [formData, onSubmit]
  );

  const renderFormGroup = (
    label,
    name,
    options,
    placeholder,
    required = true
  ) => (
    <Form.Group className="mb-3">
      <Form.Label>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      {options ? (
        <Form.Select
          name={name}
          value={formData[name]}
          onChange={handleChange}
          required={required}
        >
          <option value="">{`Selecione ${label.toLowerCase()}`}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          type="text"
          name={name}
          value={formData[name]}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
        />
      )}
    </Form.Group>
  );

  return (
    <>
      <Card.Title style={{ fontSize: '1.5rem', color: 'black', fontWeight: 'bold' }}>Defina o Contexto da Questão</Card.Title>
      <p style={{ fontSize: '1rem', color: 'black' }}>Crie uma questão utilizando inteligência artificial</p>
      {loading==false && <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>
            Disciplina <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Select
            name="disciplina"
            value={formData.disciplina_id}
            onChange={handleChange}
            required
          >
            <option value="">Selecione a disciplina</option>
            {disciplinas.map((disciplina, index) => (
              <option key={index} value={disciplina.id}>
                {disciplina.name}
              </option>
            ))}
          </Form.Select>
          
        </Form.Group>
        {renderFormGroup("Tipo de questão", "tipoQuestao", TIPOS_QUESTAO)}

        {formData.tipoQuestao === "Questão objetiva" && (
          <Form.Group className="mb-3">
            <Form.Label>
              Quantidade de Alternativas{" "}
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="quantidadeAlternativas"
              value={formData.quantidadeAlternativas}
              onChange={handleChange}
              required
            >
              <option value="">Selecione a quantidade</option>
              {[2, 3, 4, 5].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        {renderFormGroup(
          "Nível de Escolaridade",
          "nivelEscolaridade",
          NIVEIS_ESCOLARIDADE
        )}

        {renderFormGroup(
          "Nível de Dificuldade",
          "nivelDificuldade",
          NIVEIS_DIFICULDADE
        )}

        {renderFormGroup("Tema principal", "tema", null, "Ex: Revolução Francesa")}

        <Form.Group className="mb-3">
          <Form.Label>Contexto</Form.Label>
          <Form.Control
            type="text"
            name="contexto"
            value={formData.contexto || ""}
            onChange={handleChange}
            placeholder="Descreva o assunto específico da questão"
          />
        </Form.Group>
        <footer className="chat-footer d-flex justify-content-between align-items-center">

          <Link to={`/questoes/register/${id}`}>
            <Button variant="secondary" className="me-2">
              <FaArrowLeft /> Voltar
            </Button>
          </Link>
          <Button variant="primary" type="submit">
            Avançar <FaArrowRight />
          </Button>
        </footer>
      </Form>}
      {loading==true && <div className="d-flex justify-content-center align-items-center">
        <CircularProgress/>
        </div>}
    </>

  );
};

const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps)(ContextForm);
