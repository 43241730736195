import React from "react";
import { Modal, Button } from "react-bootstrap";
import { MdCheck, MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";

const ValidationModal = ({
  show,
  onClose,
  onValidate,
  id,
  isEditingContext,
}) => {
  const history = useHistory();

  const handleValidate = async () => {
    try {
      onValidate();
      // onClose();
    } catch (error) {
      console.error("Erro ao validar a questão:", error);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          Validar Questão
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ padding: "1.5rem", fontSize: "1rem", lineHeight: "1.5" }}
      >
        <p>
          Essa questão foi criada por IA. Você deve confirmar a qualidade da
          questão gerada.
        </p>
        <p><b>Você tem certeza que deseja validar esta questão?</b> </p>
        <p>Ao confirmar, será redirecionado para a página de <b>"Criar Questão"</b>, onde poderá editar seu conteúdo como desejar.</p>
        {/* {isEditingContext && (
          <div style={{ color: "red", fontWeight: "bold", marginTop: "1rem" }}>
            Certifique-se de definir o contexto da questão.
          </div>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
        <MdClose/> Cancelar 
        </Button>
        <Button
          variant="primary"
          onClick={handleValidate}
        >
          Validar <MdCheck/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ValidationModal;
