import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { URL } from '../../variables';
import { jsPDF } from 'jspdf';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { RiArrowGoBackLine } from 'react-icons/ri';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button } from 'react-bootstrap';

const DashboardTreinamento = () => {
    const { id } = useParams();
    const history = useHistory();
    const [data, setData] = useState(null);
    const [treinamento, setTreinamento] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}api/treinamento/fiscal/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
                setTreinamento(result.training);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const downloadPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(22);
        doc.text(`${treinamento.nome}`, 105, 20, null, null, 'center');

        doc.setLineWidth(0.5);
        doc.line(10, 30, 200, 30);

        doc.setFontSize(14);
        if (data && data.users && data.users.length > 0) {
            data.users.forEach((user, index) => {
                const yPosition = 40 + (index * 30);
                if (index > 0) {
                    doc.line(10, yPosition - 10, 200, yPosition - 10);
                }
                doc.text(`Fiscal: ${user.name}`, 10, yPosition);
                doc.text(`Concluiu o treinamento: ${user.concluido ? 'Sim' : 'Não'}`, 10, yPosition + 10);
            });
        } else {
            doc.text('Nenhum usuário encontrado.', 10, 40);
        }

        doc.save('treinamento.pdf');
    };

    if (loading) {
        return (
            <div className="container mt-5">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-end mb-3">
                            <Skeleton width={100} height={40} />
                        </div>
                        <h1 className="mb-4"><Skeleton width={300} /></h1>
                        <div className="list-group">
                            {[1, 2, 3].map((_, index) => (
                                <div key={index} className="list-group-item">
                                    <p className="mb-1"><Skeleton width={200} /></p>
                                    <p className="mb-1"><Skeleton width={150} /></p>
                                </div>
                            ))}
                        </div>
                        <Skeleton width={150} height={40} className="mt-4" />
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>Error: {error.message}</div>;
    }

    return (
        <div className="container mt-5">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-end mb-3">
                        <DefaultButton
                            bg="secondary"
                            text="Voltar"
                            icon={<RiArrowGoBackLine />}
                            onClick={() => history.goBack()}
                        />
                    </div>
                    <h1 className="mb-4">{treinamento.nome}</h1>
                    {data && data.users && data.users.length > 0 ? (
                        <div className="list-group">
                            {data.users.map((user, index) => (
                                <div key={index} className="list-group-item">
                                    <p className="mb-1"><strong>Fiscal:</strong> {user.name}</p>
                                    <p className="mb-1"><strong>Concluiu o treinamento: </strong> {user.concluido ? 'Sim' : 'Não'}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="alert alert-warning" role="alert">
                            Nenhum usuário encontrado.
                        </div>)}
                    <Button
                        variant="success"
                        onClick={downloadPDF}
                        className="mt-4">
                        Baixar PDF
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DashboardTreinamento;