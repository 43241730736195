import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { logout } from '../../actions/AppActions';
import JoditEditor from 'jodit-react';
// import 'jodit/build/jodit.min.css';

class CriarQuestaoConteudo extends Component {
    _isMounted = false;
    alternativasRefs = []; 

    constructor(props) { 
        super(props);
        this.state = {
            redirect: false,
            path: '/conteudo/show/',
            nome: '',
            enunciado: '',
            alternativa_correta: '',
            loadingSave: false,
            msgErro: '',
            alternativas: Array(4).fill(''),
            quantidadeAlternativas: 4,
        };

        this.alternativasRefs = Array.from({ length: 4 }, () => createRef());
        this.enunciadoRef = createRef();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    removeHtmlTags = (str) => {
        if (!str) return '';
        return str.toString().replace(/<[^>]*>/g, '');
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loadingSave: true, msgErro: '' });

        const { nome, enunciado, alternativas, alternativa_correta } = this.state;
        const conteudo_id = localStorage.getItem('idContent');
        const text_enunciado_questao = this.removeHtmlTags(enunciado);

        const alternativasSemHtml = alternativas.map((alternativa, index) => ({
            nome: this.removeHtmlTags(alternativa),
            alternativa_correta: alternativa_correta === String.fromCharCode(97 + index)
        }));

        if (!nome || !nome.trim().length || !text_enunciado_questao || !text_enunciado_questao.trim().length || alternativasSemHtml.some(alternativa => !alternativa.nome.trim().length)) {
            this.setState({ msgErro: 'Todos os campos são obrigatórios', loadingSave: false });
            return;
        }

        try {
            const responseQuestao = await fetch(`${URL}api/conteudo/store_question`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
                body: JSON.stringify({ nome, enunciado, conteudo_id, text_enunciado_questao, user_id: this.props.user.id })
            });

            if (!responseQuestao.ok) {
                const errorData = await responseQuestao.json();
                const errors = Object.values(errorData.errors || errorData.error || {});
                const errorMessage = errors.join('\n');
                console.error(errorMessage);
                if (this._isMounted) {
                    this.setState({ msgErro: errorMessage });
                }
                window.scrollTo(0, 0);
                return;
            }

            const responseData = await responseQuestao.json();
            const questao_id = responseData.question_id;

            const alternativasEnviadas = alternativasSemHtml.map((alternativa, index) => ({
                name: alternativa.nome,
                alternativa_correta: alternativa.alternativa_correta,
                questao_id,
                order: String.fromCharCode(97 + index)
            }));

            const responseAlternativas = await fetch(`${URL}api/conteudo/store_alternative_object`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
                body: JSON.stringify(alternativasEnviadas)
            });

            if (!responseAlternativas.ok) {
                const errorData = await responseAlternativas.json();
                const errors = Object.values(errorData.errors || errorData.error || {});
                const errorMessage = errors.join('\n');
                console.error(errorMessage);
                if (this._isMounted) {
                    this.setState({ msgErro: errorMessage });
                }
                window.scrollTo(0, 0);
                return;
            }

            if (this._isMounted) {
                this.setState({ loadingSave: false, redirect: true });
            }

        } catch (err) {
            console.error(err);
            if (this._isMounted) {
                this.setState({ msgErro: 'Erro ao pegar resposta do servidor' });
            }
        } finally {
            if (this._isMounted) {
                this.setState({ loadingSave: false });
            }
        }
    };

    handleChangeEnunciado = (value) => {
        this.setState({ enunciado: value });
    };

    handleChangeQuantidadeAlternativas = (event) => {
        const quantidade = parseInt(event.target.value);
        this.setState({ quantidadeAlternativas: quantidade, alternativas: Array.from({ length: quantidade }, () => "") });
        this.alternativasRefs = Array.from({ length: quantidade }, () => createRef());
    };

    handleChangeAlternativa = (index, value) => {
        const novasAlternativas = [...this.state.alternativas];
        novasAlternativas[index] = value;
        this.setState({ alternativas: novasAlternativas });
    };

    handleSelectAlternativaCorreta = (event) => {
        const { value } = event.target;
        this.setState({ alternativa_correta: value }, () => {
        });
    };



    getAlternativaLabel = (index) => {
        return String.fromCharCode(97 + index) + ')';
    };

    editorOptions = {
        height: 400,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: 'insert_only_text',
        defaultActionOnPasteFromWord: 'insert_only_text',
        pasteHTMLActionList: [
            { value: 'insert_only_text', text: 'Insert only Text' }
        ],
        pasteFromWordActionList: [
            { value: 'insert_only_text', text: 'Insert only Text' }
        ],
        language: 'pt_br',
        toolbarButtonSize: 'large',
        buttons: "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,undo,redo,hr,link,symbol,table",
        uploader: {
            url: API_URL + 'faculdade/upload_image',
            insertImageAsBase64URI: false,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
            filesVariableName: function (t) {
                return 'imagem';
            },
            withCredentials: false,
            pathVariableName: 'path',
            method: 'POST',
            prepareData: (formdata) => {
                return formdata;
            },
            isSuccess: (e) => {
                return 'Upload realizado com sucesso';
            },
            getMessage: (e) => {
                return e;
            },
            process: (resp) => {
                let files = [];
                files.unshift(STORAGE_URL + resp.imagem);

                return {
                    files: files,
                };
            },
            error: (elem, e) => {
                console.log(e);
                console.log(elem);
            },
            defaultHandlerSuccess: function (resp, elem) {
                if (resp.files && resp.files.length) {
                    resp.files.forEach((filename) => {
                        this.selection.insertImage(filename);
                    });
                }
            },
            defaultHandlerError: (elem, e) => {
                console.log(e);
                console.log(elem);
            },
        },
    };

    render() {
        const { nome, loadingSave, msgErro, enunciado, alternativas, quantidadeAlternativas, alternativa_correta } = this.state;

        return (
            <div className="row">
                {this.state.redirect && <Redirect to={this.state.path + localStorage.getItem('idContent')} />}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body card-body-custom" id="card">
                            <h4 className="mt-0 header-title header-title-custom">Cadastrar Questão</h4>
                            <p className="paragraph-custom">Cadastre as questões do seu sistema</p>
                            <br />
                            {msgErro && (
                                <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                    </div>
                                </div>
                            )}

                            <form className="position-relative" onSubmit={this.handleSubmit}>
                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <label htmlFor="nome" className="label-custom">Nome</label>
                                    <input
                                        type="text"
                                        className="form-control input-custom"
                                        id="nome"
                                        placeholder="Nome da questão"
                                        value={nome}
                                        onChange={(e) => this.setState({ nome: e.target.value })}
                                        required
                                    />
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <label htmlFor="tipoQuestao" className="label-custom">Tipo da Questão</label>
                                    <select
                                        className="form-control"
                                        id="tipoQuestao"
                                        name="tipoQuestao"
                                        required
                                    >
                                        <option value="objetiva">Objetiva</option>
                                    </select>
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom me-3">
                                    <label htmlFor="enunciado" className="label-custom">Enunciado</label>
                                    <JoditEditor
                                        ref={this.enunciadoRef}
                                        value={enunciado}
                                        config={this.editorOptions}
                                        onBlur={newContent => this.handleChangeEnunciado(newContent)}
                                        onChange={newContent => { }}
                                    />
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <label htmlFor="quantidadeAlternativas" className="label-custom">Quantidade de Alternativas</label>
                                    <select
                                        className="form-control"
                                        id="quantidadeAlternativas"
                                        value={quantidadeAlternativas}
                                        onChange={this.handleChangeQuantidadeAlternativas}
                                    >
                                        {[2, 3, 4, 5].map((number) => (
                                            <option key={number} value={number}>{number}</option>
                                        ))}
                                    </select>
                                </div>

                                {alternativas.map((alternativa, index) => (
                                    <div className="form-group flex fdcolumn flex1 margin-bottom" key={index}>
                                        <label htmlFor={`alternativa${index}`} className="label-custom">{`Alternativa ${String.fromCharCode(97 + index)})`}</label>
                                        <JoditEditor
                                            ref={this.alternativasRefs[index]}
                                            value={alternativa}
                                            config={this.editorOptions}
                                            onBlur={newContent => this.handleChangeAlternativa(index, newContent)}
                                            onChange={newContent => { }}
                                        />
                                    </div>
                                ))}

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <label htmlFor="alternativa_correta" className="label-custom">Alternativa Correta</label>
                                    <select
                                        className="form-control"
                                        id="alternativa_correta"
                                        value={alternativa_correta}
                                        onChange={this.handleSelectAlternativaCorreta}
                                    >
                                        <option value="">Escolha a alternativa correta</option>
                                        {[...Array(quantidadeAlternativas)].map((_, index) => (
                                            <option key={index} value={String.fromCharCode(97 + index)}>
                                                {`Alternativa ${this.getAlternativaLabel(index)}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-100 d-flex justify-content-between mt-3">
                                    <DefaultButton
                                        bg="secondary"
                                        text="Voltar"
                                        icon={<RiArrowGoBackLine />}
                                        to={"/conteudo/show/" + localStorage.getItem('idContent')}
                                    />
                                    <DefaultButton
                                        type="submit"
                                        bg="confirm"
                                        text="Salvar questão"
                                        disabled={loadingSave}
                                        loadingtext="Salvando questão"
                                        loading={loadingSave}
                                    />
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarQuestaoConteudo);
