import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ResultadosRedacaoIA from "./ResultadosRedacaoIA";
import axios from "axios";
import { API_URL } from "../../variables";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Request from "../../Request";

const RedacaoIA = ({
  processo_name,
  processo_id,
  redacao_name,
  redacao_id,
  token,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [redacaoTexto, setRedacaoTexto] = useState("");
  const [chatbotResponse, setChatbotResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [temaRedacao, setTemaRedacao] = useState("");
  const [selectedRedacoes, setSelectedRedacoes] = useState([]);
  const [redacoes, setRedacoes] = useState([]);
  const [showGenerateAgain, setShowGenerateAgain] = useState(false);

  const loadingPhrases = [
    "Analisando sua redação...",
    "Identificando pontos principais...",
    "Avaliando argumentação...",
    "Verificando coerência textual...",
    "Aplicando critérios de correção...",
    "Gerando feedback personalizado...",
    "Quase lá...",
  ];

  const [currentPhrase, setCurrentPhrase] = useState(loadingPhrases[0]);

  useEffect(() => {
    if (loading) {
      let phraseIndex = 0;
      const interval = setInterval(() => {
        phraseIndex = (phraseIndex + 1) % loadingPhrases.length;
        setCurrentPhrase(loadingPhrases[phraseIndex]);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleShow = async (
    redacaoId,
  ) => {
    console.log("ID da Redação:", redacaoId);
    setShowModal(true);
    setLoading(true);
    setShowGenerateAgain(false);

    await fetchAndSetChatbotResponse(
      redacaoId,
    );
    setLoading(false);
  };

  const fetchAndSetChatbotResponse = async (
    redacaoId,
  ) => {
    try {
      const resp = await Request.Post('faculdade/avaliar-redacao', JSON.stringify({
        texto_id:redacaoId,
      }),{'Content-type':'application/json'});
      if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
      }
      else{
        setChatbotResponse(resp.correcao);
      }
      
    } catch (error) {
      console.error(
        "Erro ao obter a resposta do chatbot ou enviar os dados:",
        error
      );
      setShowGenerateAgain(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setChatbotResponse("");
    setRedacaoTexto("");
    setTemaRedacao("");
  };

  const corrigirRedacao = async (redacoes) => {
    const data = {
      processo_id: processo_id,
      redacoes: redacoes.map((redacao) => ({
        id: redacao.id,
        texto: redacao.texto,
      })),
    };

    try {
      const response = await axios.post(
        `${API_URL}faculdade/corrigir-todas-redacoes`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      alert("Correção iniciada com sucesso!");
    } catch (error) {
      console.error("Erro ao corrigir a redação:", error);
      alert("Erro ao corrigir a redação. Tente novamente mais tarde.");
    }
  };

  const handleSelectRedacao = (redacao) => {
    setSelectedRedacoes((prevSelected) =>
      prevSelected.includes(redacao.id)
        ? prevSelected.filter((redacaoId) => redacaoId !== redacao.id)
        : [...prevSelected, redacao.id]
    );
  };

  const handleSelectAllRedacoes = () => {
    if (selectedRedacoes.length === redacoes.length) {
      setSelectedRedacoes([]);
    } else {
      setSelectedRedacoes(redacoes.map((redacao) => redacao.id));
    }
  };

  const renderChatbotResponse = () => {
    const nota =chatbotResponse.nota

    let competencias = [
      {
        title: `Competência 1:`,
        content: chatbotResponse.competencia_1,
      },
      {
        title: `Competência 2:`,
        content: chatbotResponse.competencia_2,
      },
      {
        title: `Competência 3:`,
        content: chatbotResponse.competencia_3,
      },
      {
        title: `Competência 4:`,
        content: chatbotResponse.competencia_4,
      },
      {
        title: `Competência 5:`,
        content: chatbotResponse.competencia_5,
      }
    ]

    

    return (
      <div>
        <h5 className="mt-0 header-title" style={{ fontSize: 22, color: 'black',textAlign:'end' }}>Nota: {nota}</h5>
        {competencias.map((competencia, index) => (
          <div key={index}>
            <h4  style={{ fontSize: 22, color: 'black' }}>{competencia.title}</h4>
            <p style={{textAlign:'justify'}}>{competencia.content}</p>
          </div>
        ))}
      </div>
    );
  };

  const handleGenerateAgain = () => {
    handleShow(redacao_id);
  };

  return (
    <div>
      {/* <h2>Corrigir com IA</h2>
      <p>Processo: {processo_name}</p>
      <p>Redação: {redacao_name}</p> */}

      <ResultadosRedacaoIA
        processo_name={processo_name}
        processo_id={processo_id}
        redacao_name={redacao_name}
        redacao_id={redacao_id}
        onVisualizarCorrecao={handleShow}
        onCorrigirRedacao={corrigirRedacao}
        onSelectRedacao={handleSelectRedacao}
        onSelectAllRedacoes={handleSelectAllRedacoes}
        selectedRedacoes={selectedRedacoes}
      />

      <Modal show={showModal} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Correção da Redação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <CircularProgress style={{ marginBottom: "20px" }} />
              <p>{currentPhrase}</p>
            </div>
          ) : (
            renderChatbotResponse()
          )}
        </Modal.Body>
        <Modal.Footer>
          {showGenerateAgain && (
            <Button variant="primary" onClick={handleGenerateAgain}>
              Gerar Novamente
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(RedacaoIA);
