import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { URL } from "../../variables";
import { logout } from "../../actions/AppActions";
import { Pagination } from '@material-ui/lab';
import ModalQuestao from "../CategoriasQuestoes/ModalQuestao";
import { CircularProgress } from "@material-ui/core";
import Search from '../ReusableComponents/Search';
import { MdArrowRight } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BancaQuestoes = () => {
  const [questoes, setQuestoes] = useState([]);
  const [loading_questions, setLoadingQuestions] = useState(true);

  const [filteredQuestoes, setFilteredQuestoes] = useState([]);
  const [search, setSearch] = useState('');
  const [disciplines, setDisciplines] = useState([]);
  const [filters, setFilters] = useState({
    tipo_questao: "",
    escolaridade: "",
    nivel_dificuldade: "",
    disciplina: "all",
  });
  const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });
  const [errorMessage, setErrorMessage] = useState(""); // Adicione este estado
  const itemsPerPage = 5;
  const dispatch = useDispatch();
  const user = useSelector(store => store.AppReducer.user);
  const token = useSelector(store => store.AppReducer.token);
  const history = useHistory();

  const fetchQuestoes = async (page = 1, search_ = search, categoria_id = filters.disciplina) => {
    setLoadingQuestions(true)
    try {
      const response = await axios.get(
        `${URL}api/faculdade/get_questoes/${categoria_id}?page=${page}&search=${search_}&public=1&tipo_questao=${filters.tipo_questao}&escolaridade=${filters.escolaridade}&dificuldade=${filters.nivel_dificuldade}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Adiciona o token ao cabeçalho
            Accept: `application/json`, // Adiciona o token ao cabeçalho
          },
        }
      );
      setQuestoes(response.data.questoes);
      setDisciplines(response.data.categorias)
      setPagination(response.data.pagination);
      // Extract unique disciplines
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Erro ao buscar questões: Endpoint não encontrado.");
      }
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        dispatch(logout())
      }
      else {
        console.error("Erro ao buscar questões:", error);
      }
    }
    setLoadingQuestions(false)

  };

  useEffect(() => {
    fetchQuestoes();
  }, []);

  // Função para remover uma questão
 

  const applyFilters = () => {
    fetchQuestoes(pagination.page,search,filteredQuestoes.disciplina,)

  };

  // Calculate the items to display based on the current page


  // Render options for discipline filter
  const renderDisciplineOptions = () => {
    return disciplines.map((disciplina, index) => (
      <option key={index} value={disciplina.id}>
        {disciplina.name}
      </option>
    ));
  };

  // Handle page change

  // Render pagination controls


  return (
    <div className="banca-questoes container mt-5">
      <div className="card p-4 shadow-sm">
        <h4 className="mt-0 header-title " style={{ fontSize: '1.5rem', color: 'black' }}>Questões Públicas</h4>
        <p style={{ fontSize: '1rem', color: 'black' }}>Bem-vindo à Questões Públicas!</p>

        {/* Filtro de Questões */}
        <div className="mb-4">
          <h5 className="text-center mb-3">Filtrar Questões</h5>
         
          <div className="row justify-content-center">
          <div className="col-lg-6 col-12 mb-3">

          <label htmlFor="tipo_questao" className="form-label text-muted">
                Pesquisar
              </label>
          <Search hide_class placeholder="Pesquisar pelo enunciado da questão" ValueChange={value => {

            setSearch(value)

          }} />
          </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <label htmlFor="tipo_questao" className="form-label text-muted">
                Tipo de Questão
              </label>
              <select
                id="tipo_questao"
                name="tipo_questao"
                className="form-control form-select"
                value={filters.tipo_questao}
                onChange={(e) =>
                  setFilters({ ...filters, tipo_questao: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="simples">Objetiva</option>
                <option value="dissertativa">Dissertativa</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <label htmlFor="escolaridade" className="form-label text-muted">
                Escolaridade
              </label>
              <select
                id="escolaridade"
                name="escolaridade"
                className="form-control form-select"
                value={filters.escolaridade}
                onChange={(e) =>
                  setFilters({ ...filters, escolaridade: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="Ensino Fundamental">Ensino Fundamental</option>
                <option value="Ensino Médio">Ensino Médio</option>
                <option value="Ensino Superior">Ensino Superior</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mb-3">
              <label
                htmlFor="nivel_dificuldade"
                className="form-label text-muted"
              >
                Nível de Dificuldade
              </label>
              <select
                id="nivel_dificuldade"
                name="nivel_dificuldade"
                className="form-control form-select"
                value={filters.nivel_dificuldade}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    nivel_dificuldade: e.target.value,
                  })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="">Todos</option>
                <option value="Fácil">Fácil</option>
                <option value="Médio">Médio</option>
                <option value="Difícil">Difícil</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mb-3">
              <label htmlFor="disciplina" className="form-label text-muted">
                Disciplina
              </label>
              <select
                id="disciplina"
                name="disciplina"
                className="form-control form-select"
                value={filters.disciplina}
                onChange={(e) =>
                  setFilters({ ...filters, disciplina: e.target.value })
                }
                style={{ borderRadius: "8px" }}
              >
                <option value="all">Todos</option>
                {renderDisciplineOptions()}
              </select>
            </div>
          </div>
          {loading_questions == false && <div className="text-end mt-1">
            <button
              className="btn btn-primary px-4 py-2"
              style={{ borderRadius: "8px" }}
              onClick={applyFilters}
            >
              Aplicar Filtros
            </button>
          </div>}
        </div>

        {/* Renderizar lista de questões */}
        {loading_questions == false ? <>


          {questoes.length != 0 && <ul className="list-group" style={{ borderWidth: 0 }}>

            {questoes.map((questao, index) => (
              <li
                key={index}
                className="list-group-item rounded-3 shadow-lg mb-4 p-4 border-0"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <ModalQuestao questao={questao} />
                <div className="text-end mt-1">
            <button
              className="btn btn-warning px-4 py-2 mt-2"
              style={{ borderRadius: "8px" }}
              onClick={()=>{
                history.push('questoes/register/'+questao.categoria_id+'?questao_id='+questao.id)
              }}
            >
              Utilizar Questão <FaArrowRight/>
            </button>
          </div>
              </li>
            ))}
          </ul>}
          {questoes.length == 0 && (
            <p className="alert alert-warning">Nenhuma questão encontrada com os filtros aplicados</p>
          )}
          <div style={{ marginTop: 15 }} className="d-flex justify-content-end">

            <Pagination
              className={(pagination.last_page == 1 ? "hide" : "")}
              style={{ display: "flex", justifyContent: "flex-end" }}
              count={pagination.last_page}
              value={pagination.current_page}
              onChange={(_, value) => {
                console.log(_, value);
                let page = { ...pagination };
                page.current_page = value;
                setPagination(page)
                fetchQuestoes(value, search);
              }}
            />
          </div>

        </> : <div className="d-flex justify-content-center mt-3"><CircularProgress /></div>}
      </div>
    </div>
  );
};

export default BancaQuestoes;
